<template>
  <card>
    <template slot="header">
      <h3 class="card-title"> <small> {{ $t('notApiTitle') }} </small> </h3>
    </template>

    <div class="card-body">
      <div class="text-center">
        <router-link :to="{ name: 'Settings' }">
          <base-button class="ml-2" type="success" style="text-transform: uppercase;">
            {{ $t("configureAPI") }}
          </base-button>
        </router-link>

      </div>
    </div>
  </card>
</template>
<script>
import { BaseAlert } from '@/components'

export default {
  name: 'balance',
  props: ["ws"],
  components: {
    BaseAlert
  },
  data() {
    return { };
  },
  methods: {
  }
};
</script>
<style scoped>

</style>
