<template>
  <div class="row">
    <div class="col-md-12">
      <card class="min-card">
        <div class="card-body">
          <tabs type="primary" horizontal>
            <tab-pane :label='$t("Profits")'>
              <div class="card-body">
                <div type="nav-tabs">
                  <div class="row">

                    <div class="col-md-4">
                      <card>
                        <template slot="header">
                          <h4 class="card-title">{{ $t("Actual hold") }}: <span class="font-weight-bold"> {{ pendency }} </span>  </h4>
                        </template>
                      </card>
                    </div>

                    <div class="col-md-4">
                      <card>
                        <template slot="header">
                          <h4 class="card-title"> {{ $t("Next hold") }}: <span class="font-weight-bold"> {{ nextValue }} </span>  </h4>
                        </template>
                      </card>
                    </div>

                    <div class="col-md-4">
                      <card>
                        <template slot="header">
                          <h4 class="card-title"> {{ $t("Cash balance") }}: <span class="font-weight-bold"> {{ cashBalance }} </span>  </h4>
                        </template>
                      </card>
                    </div>

                    <div class="col-md-12">
                      <div v-if="profitFees.length">
                        <card>
                          <template slot="header">
                            <h4 class="card-title"> {{ $t('Cash target') }} </h4>
                            <div class="progress">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="{ 'width': percentValue + '%' }"
                                :class="{ 'progress-bar-danger': percentValue <= 30, 'progress-bar-warning': (percentValue > 30 && percentValue < 80),  'progress-bar-success': percentValue >= 80 }"
                              >
                                {{percentValue}}% <span v-if="percentValue > 70"> ({{ profitValueForMeta }}) </span>
                              </div>
                            </div>
                            <h4 class="card-title"> {{ $t('Profits') }} </h4>
                            <div>
                              <fieldset :class="{ 'opacity-25': requestOn === true }">
                                <el-table
                                  class="table-striped"
                                  :data="profitFees">

                                  <el-table-column type="expand">
                                    <template slot-scope="scope">
                                      <div class="row">
                                        <div class="col-12">
                                          <card class="text-uppercase">
                                            <template slot="header">
                                              <h4 class="card-title">
                                                {{ $t('summary') }}
                                              </h4>
                                            </template>
                                            <div class="card-body">
                                              <table class="table table-striped">
                                                <thead>
                                                <tr>
                                                  <th> {{ $t('start_date') }} </th>
                                                  <th> {{ $t('end_date') }} </th>
                                                  <th> {{ $t('revenue') }} </th>
                                                  <th> {{ $t('revenue_real') }} </th>
                                                  <th> {{ $t('unpaid') }} </th>
                                                  <th> {{ $t('value_of_referrals') }} </th>
                                                  <th> {{ $t('received') }} </th>
                                                  <th> {{ $t('profit') }} </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr :key="item.start_date" v-for="item in scope.row.items">
                                                  <td> {{ item.start_date }} </td>
                                                  <td> {{ item.end_date }} </td>
                                                  <td> R$ {{ item.revenue.toFixed(2) }} </td>
                                                  <td> R$ {{ item.revenue_real.toFixed(2) }} </td>
                                                  <td> <span :class="{ 'text-danger': true }"> R$ {{ item.unpaid.toFixed(2) }} </span> </td>
                                                  <td> <span :class="{ 'text-warning': true }"> R$ {{ item.value_of_referrals.toFixed(2) }} </span> </td>
                                                  <td> <span :class="{ 'text-success': true }"> R$ {{ item.received.toFixed(2) }} </span> </td>
                                                  <td> <strong :class="{ 'text-success': true }"> R$ {{ item.profit.toFixed(2) }} </strong> </td>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </card>
                                        </div>
                                      </div>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="110"
                                    prop="label"
                                    :label="$t('period')">
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="revenue"
                                    :label="$t('revenue')">
                                    <template slot-scope="scope">
                                      <strong> R$ {{ scope.row.revenue.toFixed(2) }} </strong>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="revenue_real"
                                    :label="$t('revenue_real')">
                                    <template slot-scope="scope">
                                      <strong> R$ {{ scope.row.revenue_real.toFixed(2) }} </strong>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="unpaid"
                                    :label="$t('unpaid')">
                                    <template slot-scope="scope">
                                      <span :class="{ 'text-danger': true }"> R$ {{ scope.row.unpaid.toFixed(2) }} </span>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="value_of_referrals"
                                    :label="$t('value_of_referrals')">
                                    <template slot-scope="scope">
                                      <span :class="{ 'text-warning': true }"> R$ {{ scope.row.value_of_referrals.toFixed(2) }} </span>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="received"
                                    :label="$t('received')">
                                    <template slot-scope="scope">
                                      <span :class="{ 'text-success': true }"> R$ {{ scope.row.received.toFixed(2) }} </span>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="profit"
                                    :label="$t('profit')">
                                    <template slot-scope="scope">
                                      <strong :class="{ 'text-success': true }"> R$ {{ scope.row.profit.toFixed(2) }} </strong>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="items"
                                    :label="$t('deposit')">
                                    <template slot-scope="scope">
                                      <strong> R$ {{ scope.row.items[0].deposit }} </strong>
                                    </template>
                                  </el-table-column>

                                </el-table>
                              </fieldset>
                            </div>
                          </template>
                        </card>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane :label='$t("Expenses")'>
              <div class="card-body">
                <div type="nav-tabs">
                  <div class="row">

                    <div class="col-md-12">
                      <div v-if="expenses.length">
                        <card>
                          <template slot="header">
                            <h4 class="card-title"> {{ $t('Expenses') }} </h4>
                            <div>
                              <fieldset :class="{ 'opacity-25': requestOn === true }">
                                <el-table
                                  class="table-striped"
                                  :data="expenses">


                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="110"
                                    prop="title"
                                    :label="$t('expenseTitle')">
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="value"
                                    :label="$t('value')">
                                    <template slot-scope="scope">
                                      <span :class="{ 'text-warning': true }"> R$ {{ scope.row.value.toFixed(2) }} </span>
                                    </template>
                                  </el-table-column>

                                  <el-table-column
                                    color="primary"
                                    style="opacity: 0;"
                                    min-width="130"
                                    prop="date"
                                    :label="$t('date')">
                                  </el-table-column>

                                </el-table>
                              </fieldset>
                            </div>
                          </template>
                        </card>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </tab-pane>

          </tabs>
        </div>
      </card>
  </div>
  </div>
</template>
<script>

import { Table, TableColumn } from 'element-ui'
import { usersList, usersPut } from "@/services/index"
import { TabPane, Tabs, Collapse, CollapseItem, Slider, BaseAlert, Modal, BasePagination } from '@/components';
import { mapGetters } from 'vuex'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    Modal,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    Slider,
    BaseAlert
  },
  data() {
    return {
      requestOn: false,
      profit: true,
      uValue: "",
      sValue: "",
      sorter: { "column": '', "type": '' },
      pendency: 0,
      nextValue: 0,
      pendencyCompany: 0,
      pendencyBusiness: 0,
      profitViews: [],
      profitFees: [],
      expenses: [],
      rows: {
        total: 0,
        current_page: 1,
        data: []
      }
    };
  },

  methods: {
    async rowsUsers(params = { page: 1}) {
      this.requestOn = true
      try {

        let hasValue = {}

        if (this.sValue.replace(/\s/g, '').length > 0)
          hasValue.search = this.sValue

        if (this.uValue.replace(/\s/g, '').length > 0)
          hasValue.uui = this.uValue


        const response = await usersList({ ...params, sorter: this.sorter, ...hasValue } )
        const { data, pendency, pendencyCompany, pendencyBusiness = 0, meta, nextValue, profitViews = [], profitFees = [], expenses = [] } = response.data

        this.pendency = pendency.toFixed(2)
        this.nextValue = nextValue.toFixed(2)
        this.pendencyCompany = pendencyCompany.toFixed(2)
        this.pendencyBusiness = pendencyBusiness.toFixed(2)
        this.expenses = expenses

        this.rows.total = meta.total
        this.rows.current_page = meta.current_page
        this.rows.data = data

        const monthsProfit = []

        for (let i = 0; i < profitFees.length; i++) {
          const row = profitFees[i]
          const key = monthsProfit.findIndex( m => m.label === row.label)
          if (key === -1) {
            monthsProfit.push(this.parseRow(row))
          } else {
            const rowParsed = this.parseRow(row)
            monthsProfit[key].revenue += rowParsed.revenue
            monthsProfit[key].revenue_real += rowParsed.revenue_real
            monthsProfit[key].received += rowParsed.received
            monthsProfit[key].unpaid += rowParsed.unpaid
            monthsProfit[key].value_of_referrals += rowParsed.value_of_referrals
            monthsProfit[key].profit += rowParsed.profit
            monthsProfit[key].items.unshift(rowParsed.items[0])
          }
        }

        this.profitFees = monthsProfit

      } catch (e) {
        this.rows.total = 0
        this.rows.current_page = 1
        this.rows.data = []
        console.info(e)
      }
      this.requestOn = false
    },

    parseRow(row) {
      return {
        label: row.label,
        revenue: parseFloat(row.revenue.toFixed(2)),
        revenue_real: parseFloat(row.revenue_real.toFixed(2)),
        received: parseFloat(row.received.toFixed(2)),
        unpaid: parseFloat(row.unpaid.toFixed(2)),
        value_of_referrals: parseFloat(row.value_of_referrals.toFixed(2)),
        profit: parseFloat(row.revenue_real.toFixed(2)) - parseFloat(row.unpaid.toFixed(2)) - parseFloat(row.value_of_referrals.toFixed(2)),
        items: [{
          start_date: row.start_date,
          end_date: row.end_date,
          revenue: parseFloat(row.revenue.toFixed(2)),
          revenue_real: parseFloat(row.revenue_real.toFixed(2)),
          received: parseFloat(row.received.toFixed(2)),
          unpaid: parseFloat(row.unpaid.toFixed(2)),
          value_of_referrals: parseFloat(row.value_of_referrals.toFixed(2)),
          deposit: parseFloat(row.deposit.toFixed(2)),
          profit: parseFloat(row.revenue_real.toFixed(2)) - parseFloat(row.unpaid.toFixed(2)) - parseFloat(row.value_of_referrals.toFixed(2)),
        }]
      }
    },

    async changePage(page) {
      await this.rowsUsers({ page })
    },

    async sortChange(row) {
      console.info(row)
      const { prop, order } = row
      const type = (order || 'a').substring(0, 1) === 'a' ? 'ASC' : "DESC"
      this.sorter = { "column": prop, "type": type }

      await this.rowsUsers({ page: 1 })
    },

    async SearchValue() {
      this.uValue = ""
      await this.rowsUsers({ page: 1 })
    },

    async UuiValue() {
      this.sValue = ""
      await this.rowsUsers({ page: 1 })
    },

    async Search() {
      await this.rowsUsers({ page: 1 })
    },

    remove2FA(userId) {
      if (confirm(this.$t("reallyRemoveJFA", { id: userId}))) {
        this.loading = true

        usersPut(userId, { two_active: false })
          .then( () => this.rowsUsers())
          .catch( error => alert(error.message))
          .then(() => this.loading = false)
      }

    },

    updateActive(userId) {
      if (confirm(this.$t("reallyRemoveJFA", { id: userId}))) {
        this.loading = true

        usersPut(userId, { is_active: true })
          .then( () => this.rowsUsers())
          .catch( error => alert(error.message))
          .then(() => this.loading = false)
      }

    },

    activateOrBlock(user) {
      const message = user.active ? this.$t("default.block") : this.$t("default.block")
      if (confirm(this.$t("default.ifAction", { action: message, id: user.id }))) {
        this.requestOn = true
        this.ws.emit('blockedUser', { userId: parseInt(user.id), action: !user.active });
        setTimeout(() => this.rowsUsers(), 5000);
      }
    },

    blockAllDebit(rows) {
      if (this.ws.connected) {
        const msg = `${ this.$t("usersDisableAll", { len: rows.length }) }`;
        if (confirm(msg)) {
          this.requestOn = true
          rows.forEach(user => {
            this.ws.emit('blockedUser', { userId: user.id, action: true });
          });

          setTimeout(() => this.rowsUsers(), 5000);
        }
      }
    }

  },

  async created() {
    await this.rowsUsers()
  },
  computed: {
    ...mapGetters({
      showAdmin: 'user/showAdmin',
    }),

    cashBalance() {
      return parseFloat(((this.profitFees.reduce((acc, row) => acc + row.profit, 0)) - this.sumExpenses).toFixed(2))
    },

    sumExpenses() {
      return parseFloat(this.expenses.reduce((acc, row) => acc + row.value, 0).toFixed(2))
    },

    metaValue() {
      let bl = 36 * 2000
      bl = bl + (bl * 0.11)

      return bl
    },

    percentValue() {
      return (((this.cashBalance) / this.metaValue) * 100).toFixed(2)
    },

    profitValueForMeta() {
      return ((this.cashBalance) - this.metaValue).toFixed(2)
    }
  },
};
</script>
<style lang="scss">
.opacity-25 {
  opacity: 0.25;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}

.progress-bar-warning {
  background-color: #de9a38;
  color: #fff;
  font-weight: bold;
  padding: 2px 5px;
}

.progress-bar-danger {
  background-color: #d9534f;
  color: #fff;
  font-weight: bold;
  padding: 2px 5px;
}

.progress-bar-success {
  background-color: #1c7932;
  color: #fff;
  font-weight: bold;
  padding: 2px 5px;
}
</style>
