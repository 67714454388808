
<template>
  <div>
    <!-- not API -->
    <div v-if="showConfigApi">
      <not-api />
    </div>

    <!-- with API -->
    <div class="row" v-else>
      <balance :ws="ws" />
      <currency :ws="ws" />
      <brokerage-situation :ws="ws" />

      <div class="col-md-12" v-if="blocked === true">
        <card class="card-body card-chart card-title text-center">
          <template slot="header">
            <base-alert type="danger">
              {{ $t('orders.deactivatedAccount') }}
            </base-alert>
          </template>
        </card>
      </div>

      <div class="col-md-12" v-if="blocked === false">
        <card>
          <template slot="header">
            <h4 class="card-title">
              {{ $t('accountStatus.title', { current }) }}
            </h4>
          </template>

          <div class="row">

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                    {{ $t('accountStatus.valueInPurchases') }}  <br />
                    <strong> ${{ valueInIf(valueInPurchases) }} </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                    {{ $t('accountStatus.deficit') }}  <br />
                    <strong :class="situationClass(-deficit)"> $-{{ valueInIf(deficit) }} </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                    {{ $t('accountStatus.profit') }}  <br />
                    <strong :class="situationClass(sumProfit)"> ${{ valueInIf(sumProfit) }} </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center" v-if="requestOn === false">
                    {{ $t('accountStatus.altcoinsSaved') }}  <br />
                    <strong :class="situationClass(sumSymbolsOnHold)"> ${{ valueInIf(sumSymbolsOnHold) }} </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center" v-if="requestOn === false">
                    {{ $t('accountStatus.altcoinsSold') }}  <br />
                    <strong :class="situationClass(sumOfConfirmedAmountOnHold)"> ${{ valueInIf(sumOfConfirmedAmountOnHold) }} </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center"  v-if="requestOn === false">
                    {{ $t('accountStatus.netProfit') }}  <br />
                    <strong :class="situationClass(profit)"> ${{ valueInIf(profit) }} </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                    {{ $t('accountStatus.riskRate') }}  <br />
                    <strong :class="riskRateClass(riskRate)"> {{ valueInIf(riskRate) }}% </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                    {{ $t('dashboard.capitalAtRisk') }}  <br />
                    <strong :class="riskRateClass(capitalAtRisk)"> ${{ valueInIf(capitalAtRisk) }} </strong>
                  </h4>
                </template>
              </card>
            </div>

            <div class="col-md-4">
              <card class="block-card">
                <template slot="header">
                  <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                  <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                    {{ $t('dashboard.freeCapital') }}  <br />
                    <strong> ${{ valueInIf(freeCapitalOn) }} </strong>
                  </h4>
                </template>
              </card>
            </div>
          </div>
        </card>


        <card>
          <template slot="header">
            <h4 class="card-title">
              {{ $t('accountStatus.note') }}
            </h4>
          </template>

          <div class="row">
            <div class="col-md-12">
              <base-alert type="success">
                {{ $t('risk1') }}
              </base-alert>
            </div>
            <div class="col-md-12">
              <base-alert type="warning">
                {{ $t('risk2') }}
              </base-alert>
            </div>
            <div class="col-md-12">
              <base-alert type="danger">
                {{ $t('risk3') }}
              </base-alert>
            </div>
            <div class="col-md-12">
              <base-alert type="danger">
                {{ $t('risk4') }}
              </base-alert>
            </div>
          </div>
        </card>

      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { overview, listOrders, userInfo } from "@/services"
import { BaseAlert } from '@/components'
import NotApi from "@/components/NotApi"

export default {
  props: ['ws'],
  components: {
    BaseAlert,
    NotApi
  },
  data() {
    return {
      requestOn: false,
      showConfigApi: false
    };
  },
  computed: {
    ...mapGetters({
      exchange: "user/exchange",
      current: "user/current",
      show: 'user/showBalance',
      capitalAtRisk: "user/capitalAtRisk",
      symbolsOnHold: "user/symbolsOnHold",
      symbolsOnHoldBrl: "user/symbolsOnHoldBrl",
      monthlyGrossProfit: "user/monthlyGrossProfit",
      monthlyGrossProfitBrl: "user/monthlyGrossProfitBrl",
      sumProfit: "user/sumProfit",
      sumProfitBrl: "user/sumProfitBrl",
      valueInPurchases: "user/valueInPurchases",
      sumOfConfirmedAmountOnHold: "user/sumOfConfirmedAmountOnHold",
      blocked: "user/blocked",
      operations: 'user/openOperations',
      riskRate: "user/riskRate",
      riskRateBrl: "user/riskRateBrl",
      deficit: "user/deficit",
      deficitBrl: "user/deficitBrl",
      symbolByGroup: "symbols/symbolByGroup",
      usd: "user/usdCurrency",
      onlyView: "user/onlyView",
      freeCapitalOn: "user/freeCapitalOn"
    }),
    sumSymbolsOnHold: function() {
      const value = this.symbolsOnHold
        .filter(a => a.amount > 0)
        .map(s => s.currentValue)
        .reduce((a, b) => a + b, 0).toFixed(2)

      return parseFloat(value)
    },

    sumSymbolsOnHoldBrl:  function() {
      const value = this.symbolsOnHoldBrl
        .filter(a => a.amount > 0)
        .map(s => s.currentValue)
        .reduce((a, b) => a + b, 0).toFixed(2)

      return parseFloat(value)
    },

    profit: function() {
      return parseFloat(((this.sumProfit + this.sumOfConfirmedAmountOnHold + this.sumSymbolsOnHold) - this.deficit).toFixed(2))
    },

  },
  methods: {
    ...mapActions({
      setSymbolsOnHold: "user/setSymbolsOnHold",
      setSymbolsOnHoldBrl: "user/setSymbolsOnHoldBrl",
      setSumProfit: "user/setSumProfit",
      setSumProfitBrl: "user/setSumProfitBrl",
      setSumOfConfirmedAmountOnHold: "user/setSumOfConfirmedAmountOnHold",
      setMonthlyGrossProfit: "user/setMonthlyGrossProfit",
      setMonthlyGrossProfitBrl: "user/setMonthlyGrossProfitBrl",
      setGain: "user/setGain"
    }),

    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    showSituation(v1, v2) {
      return parseFloat((((v1 / v2) * 100) - 100).toFixed(2));
    },

    situationClass(risk) {
      if (risk < 0) return 'text-warning';
      return 'text-success'
    },

    overview() {
      this.requestOn = true

      overview()
        .then(({ sumOfConfirmedAmountOnHold = 0, sumProfitCoinex, sumProfitFtx, sumProfit = 0, sumProfitHuobi = 0, sumProfitKucoin = 0, sumProfitOkex = 0, symbolsOnHoldKucoin = [], symbolsOnHoldOkex = [], sumProfitBrl = 0, symbolsOnHoldHuobi = [], symbolsOnHold = [], symbolsOnHoldBrl = [] }) => {

          // setSumProfit binance
          const binanceSumProfit = { exchange: 'binance', value: sumProfit }
          const binanceSumProfitBrl = { exchange: 'binance', value: sumProfitBrl }
          this.setSumProfit(binanceSumProfit)
          this.setSumProfitBrl(binanceSumProfitBrl)

          // setSumProfit huobi
          const huobiSumProfit = { exchange: 'huobi', value: sumProfitHuobi}
          this.setSumProfit(huobiSumProfit)

          // setSumProfit coinex
          const coinexSumProfit = { exchange: 'coinex', value: sumProfitCoinex }
          this.setSumProfit(coinexSumProfit)

          // setSumProfit ftx
          const ftxSumProfit = { exchange: 'ftx', value: sumProfitFtx }
          this.setSumProfit(ftxSumProfit)

          // setSumProfit kucoin
          const kucoinSumProfit = { exchange: 'kucoin', value: sumProfitKucoin}
          this.setSumProfit(kucoinSumProfit)

          // setSumProfit okex
          const okexSumProfit = { exchange: 'okex', value: sumProfitOkex}
          this.setSumProfit(okexSumProfit)

          // setSymbolsOnHold binance
          const binanceSymbolsOnHold = { exchange: 'binance', value: symbolsOnHold }
          const binanceSymbolsOnHoldBrl = { exchange: 'binance', value: symbolsOnHoldBrl }
          this.setSymbolsOnHold(binanceSymbolsOnHold)
          this.setSymbolsOnHoldBrl(binanceSymbolsOnHoldBrl)

          // setSymbolsOnHold huobi
          const huobiSymbolsOnHold = { exchange: 'huobi', value: symbolsOnHoldHuobi }
          this.setSymbolsOnHold(huobiSymbolsOnHold)

          // setSymbolsOnHold kucoin
          const kucoinSymbolsOnHold = { exchange: 'kucoin', value: symbolsOnHoldKucoin }
          this.setSymbolsOnHold(kucoinSymbolsOnHold)

          // setSymbolsOnHold okex
          const okexSymbolsOnHold = { exchange: 'okex', value: symbolsOnHoldOkex }
          this.setSymbolsOnHold(okexSymbolsOnHold)

          // setSumOfConfirmedAmountOnHold binance
          const binancesumOfConfirmedAmountOnHold = { exchange: 'binance', value: sumOfConfirmedAmountOnHold }
          this.setSumOfConfirmedAmountOnHold(binancesumOfConfirmedAmountOnHold)
        })
        .then(() => {
          this.requestOn = false
        })
    },

    async hasApi() {
      const { data } = await userInfo()
      this.showConfigApi = (!data.apiIsActive && !data.apiIsActiveFtx && !data.apiIsActiveHuobi && !data.apiIsActiveKucoin && !data.apiIsActiveOkex) ? true : false
    }
  },
  async created() {
    this.overview()

    const resOrders = await listOrders()
    this.setGain(resOrders.data)

    await this.hasApi()
  }
};
</script>
<style scoped>
.block-card {
  min-height: 130px!important;
}
</style>
