<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">
            {{ $t("ranking.title") }} ({{ symbols.length }})
            <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
          </h4>
        </template>
        <div>
          <el-table class="table-striped"
            height="67vh"
            :data="symbols.filter(data => !search || data.symbol.toLowerCase().includes(search.toLowerCase()))"
            :default-sort = "{ prop: 'rank' }">

            <el-table-column
              color="primary"
              min-width="100"
              prop="symbol"
              :label="$t('symbol')">
              <template slot="header">
                <base-input v-model="search"
                  type="text"
                  :placeholder="$t('symbol')"/>
              </template>
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="100"
              prop="rank"
              sortable
              :label="$t('ranking.title')">
              <template slot-scope="scope">
                {{ scope.row.rank === 10000 ? $t("unavailable") : scope.row.rank }}
              </template>
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="100"
              prop="level"
              sortable
              :label="$t('ranking.level')">
            </el-table-column>
          </el-table>
          <br />
          <br />
          {{ $t("source") }} : <a href="https://tokeninsight.com/en/cryptocurrencies" target="_black"> tokeninsight.com </a>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { listSymbols } from "@/services"
import {Table, TableColumn} from 'element-ui'
import { BaseAlert, BaseCheckbox, Badge, Modal } from '@/components'
export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    BaseCheckbox,
    Badge,
    Modal
  },
  data() {
    return {
      search: "",
      symbols: []
    };
  },
  computed: {

  },
  methods: {
    async listSymbols() {
      try {
        let filtered = []
        this.symbols = []

        const res = await listSymbols()
        const raws = res.data
          .filter(s => s.showUser)
          .forEach(s => {
            const has = filtered.findIndex(f => f.symbol === s.group.toUpperCase())
            if (has === -1) {
              filtered.push({
                symbol: `${s.group.toUpperCase()}`,
                level: s.level || this.$t("unavailable"),
                rank: parseInt(s.rank || 10000),
              })
            }
          })

        this.symbols = filtered
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {

  },
  async created() {
    await this.listSymbols()
  }
};
</script>
