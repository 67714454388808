<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">
            {{ $t("currentSituation.availableParities") }} ({{ symbols.length }})
          </h4>
        </template>
        <div>
          <el-table class="table-striped"
            height="67vh"
            :data="symbols"
            :default-sort = "{ prop: 'symbol', order: 'descending' }">
            <el-table-column
              color="primary"
              min-width="100"
              prop="exchange"
              sortable
              :label="$t('closeOperations.exchange')">
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="100"
              prop="symbol"
              sortable
              :label="$t('orders.par')">
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="100"
              prop="min_value"
              sortable
              :label="$t('orders.minimumValue')">
            </el-table-column>
          </el-table>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { listSymbols } from "@/services"
import {Table, TableColumn} from 'element-ui'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      symbols: []
    };
  },
  computed: {

  },
  methods: {
    async listSymbols() {
      try {
        const res = await listSymbols()
        this.symbols = res.data.filter(s => s.showUser).map(s => ({
          id: s.id,
          symbol: `${s.group.toUpperCase()}${s.currency.toUpperCase()}`,
          exchange: s.exchange.toUpperCase(),
          symbolWithExchange: `${s.exchange.toUpperCase()}-${s.symbol.toUpperCase()}`,
          buyNow: parseFloat(s.priceNow),
          currency: s.currency,
          group: s.group,
          min_value: s.min_value
        }))
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {

  },
  async created() {
    await this.listSymbols()
  }
};
</script>
