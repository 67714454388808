<template>
  <div>
    <!-- not API -->
    <div v-if="showConfigApi">
      <not-api />
    </div>

    <!-- with API -->
    <div class="row" v-else>
      <balance :ws="ws" />
      <currency :ws="ws" />
      <brokerage-situation :ws="ws" />


      <div class="col-md-6" v-if="blocked === false">
        <card class="card-chart">
          <template slot="header">
            <h4 class="card-title"> {{ $t('dashboard.daysPerformance', { current }) }} <br /> <small class="big-small"> ( {{ $t('timeUpdate') }} ) </small> </h4>
          </template>
          <div class="card-body">

            <div class="spinner-grow spinner-grow-xl" v-loading="requestOn || !changeBroker"> </div>
              <line-chart
                v-if="days.chartData.labels.length && requestOn === false && changeBroker"
                :chart-data="days.chartData"
                :gradient-colors="days.gradientColors"
                :gradient-stops="days.gradientStops"
                :extra-options="days.extraOptions"
                :height="200"
              >
              </line-chart>
            <div class="text-center" v-if="!days.chartData.labels.length && requestOn === false && changeBroker"> {{ $t('dashboard.none') }} </div>
          </div>
        </card>
      </div>

      <div class="col-md-6" v-if="blocked === false">
        <card class="card-chart">
          <template slot="header">
            <h4 class="card-title"> {{ $t('dashboard.monthlyPerformance', { current  }) }}  <br /> <small class="big-small"> ( {{ $t('timeUpdate') }} ) </small> </h4>
          </template>
          <div class="card-body">

            <div class="spinner-grow spinner-grow-xl" v-loading="requestOn || !changeBroker"> </div>

            <bar-chart
              v-if="months.chartData.labels.length && requestOn === false && changeBroker"
              :chart-data="months.chartData"
              :extra-options="months.extraOptions"
              :height="200"
            >
            </bar-chart>
            <div class="text-center" v-if="!months.chartData.labels.length && requestOn === false && changeBroker"> {{ $t('dashboard.none') }} </div>
          </div>
        </card>
      </div>

      <div class="col-md-3" v-if="blocked === false">
        <card class="block-card">
          <template slot="header">
            <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
            <h4 class="card-title text-center"  v-if="requestOn === false">
              {{ $t('dashboard.monthlyGrossProfit', { current }) }}  <br />
              <strong> ${{ valueInIf(monthlyGrossProfit) }} </strong>
            </h4>
          </template>
        </card>
      </div>

      <div class="col-md-3" v-if="blocked === false">
        <card class="block-card">
          <template slot="header">
            <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
            <h4 class="card-title text-center"  v-if="requestOn === false">
              {{ $t('dashboard.openOperations') }}  <br />
              <strong> {{ operations.length }} </strong>
            </h4>
          </template>
        </card>
      </div>

      <div class="col-md-3" v-if="blocked === false">
        <card class="block-card">
          <template slot="header">
            <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
            <h4 class="card-title text-center text-danger" v-if="requestOn === false">
              {{ $t('dashboard.riskRate', { current }) }}  <br />
              <strong :class="riskRateClass(riskRate)"> {{ valueInIf(riskRate) }}% </strong>
            </h4>
          </template>
        </card>
      </div>

      <div class="col-md-3" v-if="blocked === false">
        <card class="block-card">
          <template slot="header">
            <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
            <h4 class="card-title text-center"  v-if="requestOn === false">
              {{ $t('dashboard.netProfit', { current }) }}  <br />
              <strong :class="situationClass(profit)"> ${{ valueInIf(profit) }} </strong>
            </h4>
          </template>
        </card>
      </div>

      <div class="col-md-3 col-md-auto" v-if="blocked === false"></div>
      <div class="col-md-3 col-md-auto" v-if="blocked === false">
        <card class="block-card">
          <template slot="header">
            <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
            <h4 class="card-title text-center" v-if="requestOn === false">
              {{ $t('dashboard.altcoinsSaved', { current }) }}  <br />
              <strong> ${{ valueInIf(sumSymbolsOnHold) }} </strong>
            </h4>
          </template>
        </card>
      </div>


      <div class="col-md-3 col-md-auto" v-if="blocked === false">
        <card class="block-card">
          <template slot="header">
            <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
            <h4 class="card-title text-center" v-if="requestOn === false">
              {{ $t('dashboard.altcoinsSold', { current }) }}  <br />
              <strong> ${{ valueInIf(sumOfConfirmedAmountOnHold) }} </strong>
            </h4>
          </template>
        </card>
      </div>

      <div class="col-md-12" v-if="blocked === true">    <div class="col-md-3 col-md-auto" v-if="blocked === false"></div>
        <card class="card-body card-chart card-title text-center">
          <template slot="header">
            <base-alert type="danger">
              {{ $t('orders.deactivatedAccount') }}
            </base-alert>
          </template>
        </card>
      </div>

      <div class="col-md-12" v-if="blocked === false">
        <card>
          <template slot="header">
            <h4 class="card-title">
              {{ $t('dashboard.coinSaved', { exchange, current  }) }} ({{ symbolsOnHold.filter(a => a.amount > 0 && a.currentValue > 0).length }})
              <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
            </h4>
          </template>

          <el-table class="table-striped" v-if="symbolsOnHold.filter(a => a.amount > 0 && a.currentValue > 0).length && requestOn === false"
            height="43vh"
            :default-sort = "{ prop: 'currentValue', order: 'descending' }"
            :data="symbolsOnHold.filter(a => a.amount > 0 && a.currentValue > 0).filter(data => !search || data.symbol.toLowerCase().includes(search.toLowerCase()))">

              <el-table-column
                color="primary"
                style="opacity: 0;"
                width="160"
                prop="symbol"
                :label="$t('dashboard.altcoin')">
                <template slot="header">
                  <base-input  v-model="search"
                    type="text"
                    :placeholder="$t('dashboard.altcoin')"/>
                </template>
              </el-table-column>

              <el-table-column
                prop="amount"
                min-width="150"
                :label="$t('dashboard.amount')"
                sortable>
                <template slot-scope="scope">
                  {{ scope.row.amount }}
                </template>
              </el-table-column>

              <el-table-column
                prop="invested"
                min-width="150"
                :label="$t('dashboard.invested')"
                sortable>
                <template slot-scope="scope">
                  ${{ valueInIf(scope.row.invested) }}
                </template>
              </el-table-column>

              <el-table-column
                prop="currentValue"
                min-width="160"
                :label="$t('dashboard.currentValue')"
                sortable>
                <template slot-scope="scope">
                  ${{ valueInIf(scope.row.currentValue) }}
                </template>
              </el-table-column>

              <el-table-column
                prop="situation"
                min-width="150"
                :label="$t('dashboard.situation')"
                sortable>
                <template slot-scope="scope">
                  <span :class="situationClass(scope.row.situation)"> {{ valueInIf(scope.row.situation) }}% </span>
                </template>
              </el-table-column>

              <el-table-column
                prop="action"
                min-width="230"
                :label="$t('howToSell')">
                <template slot-scope="scope">
                  <base-button class="btn btn-warning btn-sm mr-4 mt-2 mb-2" size="sm" v-if="showActionGroup(scope.row, true)" :disabled="requestSellOrInfo" @click="cleanNowHold(scope.row)">
                    {{ $t("dashboard.informSale") }}
                  </base-button>

                  <base-button class="btn btn-success btn-sm mt-2 mb-2 ml-4" size="sm" v-if="showActionGroup(scope.row)" :disabled="requestSellOrInfo" @click="sellNowHold(scope.row)">
                    {{ $t("dashboard.sell") }}
                  </base-button>

                  <base-button class="btn btn-warning btn-sm mt-2 mb-2 ml-4" size="sm" disabled="true" v-else>
                    {{ $t("dashboard.sell") }}
                  </base-button>
                </template>
              </el-table-column>

          </el-table>
          <div class="text-center" v-if="symbolsOnHold.filter(a => a.amount > 0 && a.currentValue > 0).length === 0 && requestOn === false"> {{ $t('dashboard.none') }} </div>
          <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
        </card>
      </div>

      <modal size="xl"  v-if="modalOpenBinanceAlert" :show.sync="modalOpenBinanceAlert">

        <alert>
          <div class="clearfix">
              <base-button class="ml-2 float-right" size="sm" simple type="primary" @click="notShow()"> {{ $t("no longer display") }} </base-button>
              <base-button class="ml-2 float-left" size="sm" simple type="primary" @click="modalOpenBinanceAlert = false"> x </base-button>
            </div>
            <div>
              <strong> {{ $t('infoTitleBinance') }} </strong>
            </div>
            <div>
              {{ $t('infoText1Binance') }} <br />
              {{ $t('infoText2Binance') }} <br />
              {{ $t('infoText3Binance') }} <br />
              {{ $t('infoText4Binance') }} <br />
            </div>

            <div class="embed-responsive center" style="min-height:320px;max-width: 610px;">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Pi3SZ-mT854?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div>
              <strong class="mb-2">
              {{ $t('infoText5Binance') }} <br />
              {{ $t('infoText6Binance') }}  <br />
              {{ $t('infoText7Binance') }}  <br />
              </strong>
            </div>
        </alert>
        <template slot="footer"> </template>
      </modal>

    </div>
  </div>

</template>
<script>

import moment from 'moment';
import BarChart from '@/components/Charts/BarChart';
import LineChart from 'src/components/Charts/LineChart';
import { charts, listOrders, sellAccomplished, sellInfo, userInfo } from "@/services"
import { mapActions, mapGetters } from 'vuex'
import {Table, TableColumn} from 'element-ui'
import { BaseNav, Badge, BaseAlert, Modal } from '@/components'
import { barMonthlyPerformance, lineDaysPerformance } from "./charts"
import NotApi from "@/components/NotApi"

export default {
  props: ['ws'],
  components: {
    Modal,
    BarChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseNav,
    BaseAlert,
    LineChart,
    Badge,
    NotApi
  },
  data() {
    return {
      modalOpenBinanceAlert: false,
      requestOn: false,
      requestSellOrInfo: false,
      search: "",
      showConfigApi: false
    };
  },
  computed: {
    ...mapGetters({
      show: 'user/showBalance',
      exchange: "user/exchange",
      changeBroker: "user/changeBroker",
      findByOrderId: "user/findByOrderId",
      gainByOrderIdInfo: "user/gainByOrderIdInfo",
      gainByOrderGroupAndExchange: "user/gainByOrderGroupAndExchange",
      current: "user/current",
      days: "user/days",
      daysBrl: "user/daysBrl",
      months: "user/months",
      monthsBrl: "user/monthsBrl",
      symbolsOnHold: "user/symbolsOnHold",
      symbolsOnHoldBrl: "user/symbolsOnHoldBrl",
      monthlyGrossProfit: "user/monthlyGrossProfit",
      monthlyGrossProfitBrl: "user/monthlyGrossProfitBrl",
      sumProfit: "user/sumProfit",
      sumProfitBrl: "user/sumProfitBrl",
      sumOfConfirmedAmountOnHold: "user/sumOfConfirmedAmountOnHold",
      blocked: "user/blocked",
      operations: 'user/openOperations',
      riskRate: "user/riskRate",
      riskRateBrl: "user/riskRateBrl",
      deficit: "user/deficit",
      deficitBrl: "user/deficitBrl",
      symbolByGroup: "symbols/symbolByGroup",
      usd: "user/usdCurrency",
      onlyView: "user/onlyView",
      brokers: "exchange/brokers",
    }),

    sumSymbolsOnHold: function() {
      const value = this.symbolsOnHold
        .filter(a => a.amount > 0 && a.currentValue > 0)
        .map(s => s.currentValue)
        .reduce((a, b) => a + b, 0).toFixed(2)

      return parseFloat(value)
    },

    sumSymbolsOnHoldBrl:  function() {
      const value = this.symbolsOnHoldBrl
        .filter(a => a.amount > 0 && a.currentValue > 0)
        .map(s => s.currentValue)
        .reduce((a, b) => a + b, 0).toFixed(2)

      return parseFloat(value)
    },

    profit: function() {
      return parseFloat(((this.sumProfit + this.sumOfConfirmedAmountOnHold + this.sumSymbolsOnHold) - this.deficit).toFixed(2))
    },
  },
  async created() {
    await this.charts()

    const resOrders = await listOrders()
    this.setGain(resOrders.data)

    const show = localStorage.getItem('modalOpenBinanceAlert')
    if (show === null) {
      this.modalOpenBinanceAlert = true
    }

    await this.hasApi()
  },
  methods: {
    ...mapActions({
      setSymbolsOnHold: "user/setSymbolsOnHold",
      setSymbolsOnHoldBrl: "user/setSymbolsOnHoldBrl",
      setSumProfit: "user/setSumProfit",
      setDays: "user/setDays",
      setDaysBrl: "user/setDaysBrl",
      setMonths: "user/setMonths",
      setMonthsBrl: "user/setMonthsBrl",
      setSumProfitBrl: "user/setSumProfitBrl",
      setSumOfConfirmedAmountOnHold: "user/setSumOfConfirmedAmountOnHold",
      setMonthlyGrossProfit: "user/setMonthlyGrossProfit",
      setMonthlyGrossProfitBrl: "user/setMonthlyGrossProfitBrl",
      setGain: "user/setGain"
    }),

    notShow() {
      this.modalOpenBinanceAlert = false
      localStorage.setItem('modalOpenBinanceAlert', 'true')
    },

    async sellNowHold(row) {
      const orders = this.gainByOrderGroupAndExchange(row.symbol, this.exchange).filter(item => this.showAction(item, false) === true)
      const symbols = orders.map(o => `${o.symbolPar}${o.currency}`).join(",")

      if(confirm(this.$t('dashboard.sellHold', { coin: symbols }))) {
        row.request = true
        this.requestSellOrInfo = true
        setTimeout(() => this.requestSellOrInfo = false, 3400);

        for (let index = 0; index < orders.length; index++) {
          try {

            const body = {
              "orderId": orders[index].orderId,
              "sellOnHold": orders[index].symbolOnHold
            }

            await sellAccomplished(body)

          } catch (e) {
            const { status, data = [] } = e.response || {}
            let message = e.message
            if (status === 422 || status === 400) {
              let errors = []
              for (const item in data) {
                errors.push(data[item].join("\n"))
              }
              message = errors.join("\n")
            }
            this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
          }
        }


        const resOrders = await listOrders()
        this.setGain(resOrders.data)
      }
    },

    async cleanNowHold(row) {
      const orders = this.gainByOrderGroupAndExchange(row.symbol, this.exchange);
      const symbols = orders.map(o => `${o.symbolPar}${o.currency}`).join(",")

      if(confirm(this.$t('dashboard.cleanHold', { coin: symbols }))) {
        row.request = true
        this.requestSellOrInfo = true
        setTimeout(() => this.requestSellOrInfo = false, 3400);


        for (let index = 0; index < orders.length; index++) {
          try {
            const body = {
              orderId: orders[index].orderId
            }

            await sellInfo(body)

            this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 });
          } catch (e) {
            const { status, data = [] } = e.response || {}
            let message = e.message
            if (status === 422 || status === 400) {
              let errors = []
              for (const item in data) {
                errors.push(data[item].join("\n"))
              }
              message = errors.join("\n")
            }
            this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
          }
        }

        const resOrders = await listOrders()
        this.setGain(resOrders.data)
      }
    },

    showActionGroup(row, info = false) {
      if (row.request === true) return false;

      return this.gainByOrderGroupAndExchange(row.symbol, this.exchange).filter(item => this.showAction(item, info)).length
    },

    showAction (row, info = false) {
      if (row.exchange.toUpperCase() === "BINANCE" && info === false && ((row.symbolOnHold || 0) * this.findByOrderId(row.orderId).price || 0) < 12)
        return false

      if (row.exchange.toUpperCase() === "OKEX" && info === false && ((row.symbolOnHold || 0) * this.findByOrderId(row.orderId).price || 0) < 10)
        return false

      if (row.exchange.toUpperCase() === "KUCOIN" && info === false && ((row.symbolOnHold || 0) * this.findByOrderId(row.orderId).price || 0) < 5)
        return false

      if (row.exchange.toUpperCase() === "HUOBI" && info === false && ((row.symbolOnHold || 0) * this.findByOrderId(row.orderId).price || 0) < 7)
        return false

      if (((row.symbolOnHold || 0) * this.findByOrderId(row.orderId).price || 0) === 0)
        return false

      return true
    },

    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    showSituation(v1, v2) {
      return parseFloat((((v1 / v2) * 100) - 100).toFixed(2));
    },

    situationClass(risk) {
      if (risk < 0) return 'text-warning';
      return 'text-success'
    },

    charts() {
      this.requestOn = true

      charts()
        .then(({
          sumProfitHuobi,
          sumProfitCoinex,
          sumProfitFtx,
          symbolsOnHoldHuobi,
          symbolsOnHoldKucoin,
          symbolsOnHoldCoinex,
          symbolsOnHoldFtx,
          symbolsOnHoldOkex,
          monthlyGrossProfitKucoin,
          monthlyGrossProfitCoinex,
          monthlyGrossProfitFtx,
          monthlyGrossProfitOkex,
          sumProfitKucoin,
          sumProfitOkex,
          lastThirtyDaysKucoin,
          lastThirtyDaysCoinex,
          lastThirtyDaysFtx,
          lastThirtyDaysOkex,
          lastSixMonthsEveryFortnightKucoin,
          lastSixMonthsEveryFortnightCoinex,
          lastSixMonthsEveryFortnightFtx,
          lastSixMonthsEveryFortnightOkex,
          lastSixMonthsEveryFortnight,
          monthlyGrossProfitHuobi,
          lastThirtyDaysHuobi,
          lastSixMonthsEveryFortnightHuobi,
          lastSixMonthsEveryFortnightBrl,
          lastThirtyDays,
          lastThirtyDaysBrl,
          monthlyGrossProfit = 0,
          monthlyGrossProfitBrl = 0,
          sumOfConfirmedAmountOnHold = 0,
          sumProfit = 0,
          sumProfitBrl = 0,
          symbolsOnHold = [],
          symbolsOnHoldBrl = [] }) => {

          // setSumProfit binance
          const binanceSumProfit = { exchange: 'binance', value: sumProfit }
          const binanceSumProfitBrl = { exchange: 'binance', value: sumProfitBrl }
          this.setSumProfit(binanceSumProfit)
          this.setSumProfitBrl(binanceSumProfitBrl)

          // setSumProfit huobi
          const huobiSumProfit = { exchange: 'huobi', value: sumProfitHuobi }
          this.setSumProfit(huobiSumProfit)

          // setSumProfit coinex
          const coinexSumProfit = { exchange: 'coinex', value: sumProfitCoinex }
          this.setSumProfit(coinexSumProfit)

          // setSumProfit ftx
          const ftxSumProfit = { exchange: 'ftx', value: sumProfitFtx }
          this.setSumProfit(ftxSumProfit)

          // setSumProfit kucoin
          const kucoinSumProfit = { exchange: 'kucoin', value: sumProfitKucoin }
          this.setSumProfit(kucoinSumProfit)

          // setSumProfit okex
          const okexSumProfit = { exchange: 'okex', value: sumProfitOkex }
          this.setSumProfit(okexSumProfit)

          // setSymbolsOnHold binance
          const binanceSymbolsOnHold = { exchange: 'binance', value: symbolsOnHold }
          const binanceSymbolsOnHoldBrl = { exchange: 'binance', value: symbolsOnHoldBrl }
          this.setSymbolsOnHold(binanceSymbolsOnHold)
          this.setSymbolsOnHoldBrl(binanceSymbolsOnHoldBrl)

          // setSymbolsOnHold huobi
          const huobiSymbolsOnHold = { exchange: 'huobi', value: symbolsOnHoldHuobi }
          this.setSymbolsOnHold(huobiSymbolsOnHold)

          // setSymbolsOnHold kucoin
          const kucoinSymbolsOnHold = { exchange: 'kucoin', value: symbolsOnHoldKucoin }
          this.setSymbolsOnHold(kucoinSymbolsOnHold)

          // setSymbolsOnHold coinex
          const coinexSymbolsOnHold = { exchange: 'coinex', value: symbolsOnHoldCoinex }
          this.setSymbolsOnHold(coinexSymbolsOnHold)

          // setSymbolsOnHold ftx
          const ftxSymbolsOnHold = { exchange: 'ftx', value: symbolsOnHoldFtx }
          this.setSymbolsOnHold(ftxSymbolsOnHold)

          // setSymbolsOnHold kucoin
          const okexSymbolsOnHold = { exchange: 'okex', value: symbolsOnHoldOkex }
          this.setSymbolsOnHold(okexSymbolsOnHold)

          // setMonthlyGrossProfit binance
          const binanceMonthlyGrossProfit = { exchange: 'binance', value: monthlyGrossProfit }
          const binanceMonthlyGrossProfitBrl = { exchange: 'binance', value: monthlyGrossProfitBrl }
          this.setMonthlyGrossProfit(binanceMonthlyGrossProfit)
          this.setMonthlyGrossProfitBrl(binanceMonthlyGrossProfitBrl)

          // setMonthlyGrossProfit coinex
          const coinexMonthlyGrossProfit = { exchange: 'coinex', value: monthlyGrossProfitCoinex }
          this.setMonthlyGrossProfit(coinexMonthlyGrossProfit)

          // setMonthlyGrossProfit ftx
          const ftxMonthlyGrossProfit = { exchange: 'ftx', value: monthlyGrossProfitFtx }
          this.setMonthlyGrossProfit(ftxMonthlyGrossProfit)

          // setMonthlyGrossProfit huobi
          const huobiMonthlyGrossProfit = { exchange: 'huobi', value: monthlyGrossProfitHuobi }
          this.setMonthlyGrossProfit(huobiMonthlyGrossProfit)

          // setMonthlyGrossProfit kucoin
          const kucoinMonthlyGrossProfit = { exchange: 'kucoin', value: monthlyGrossProfitKucoin }
          this.setMonthlyGrossProfit(kucoinMonthlyGrossProfit)

          // setMonthlyGrossProfit okex
          const okexMonthlyGrossProfit = { exchange: 'okex', value: monthlyGrossProfitOkex }
          this.setMonthlyGrossProfit(okexMonthlyGrossProfit)

          // setSumOfConfirmedAmountOnHold binance
          const binancesumOfConfirmedAmountOnHold = { exchange: 'binance', value: sumOfConfirmedAmountOnHold }
          this.setSumOfConfirmedAmountOnHold(binancesumOfConfirmedAmountOnHold)

          // set days binance
          const binanceDays = lineDaysPerformance()
          const binanceDaysBrl = lineDaysPerformance()
          lastThirtyDays.forEach(row => {
            binanceDays.chartData.labels.push(`${moment(row.day).format()}`)
            binanceDays.chartData.datasets[0].data.push(row.value)
          })
          lastThirtyDaysBrl.forEach(row => {
            binanceDaysBrl.chartData.labels.push(`${moment(row.day).format()}`)
            binanceDaysBrl.chartData.datasets[0].data.push(row.value)
          })
          const binanceDaysData = { exchange: 'binance', value: binanceDays }
          const binanceDaysBrlData = { exchange: 'binance', value: binanceDaysBrl }
          this.setDays(binanceDaysData)
          this.setDaysBrl(binanceDaysBrlData)

          // set days huobi
          const huobiDays = lineDaysPerformance()
          lastThirtyDaysHuobi.forEach(row => {
            huobiDays.chartData.labels.push(`${moment(row.day).format()}`)
            huobiDays.chartData.datasets[0].data.push(row.value)
          })
          const huobiDaysDaysData = { exchange: 'huobi', value: huobiDays }
          this.setDays(huobiDaysDaysData)

          // set days ftx
          const ftxDays = lineDaysPerformance()
          lastThirtyDaysFtx.forEach(row => {
            ftxDays.chartData.labels.push(`${moment(row.day).format()}`)
            ftxDays.chartData.datasets[0].data.push(row.value)
          })
          const ftxDaysDaysData = { exchange: 'ftx', value: ftxDays }
          this.setDays(ftxDaysDaysData)

          // set days coinex
          const coinexDays = lineDaysPerformance()
          lastThirtyDaysCoinex.forEach(row => {
            coinexDays.chartData.labels.push(`${moment(row.day).format()}`)
            coinexDays.chartData.datasets[0].data.push(row.value)
          })
          const coinexDaysDaysData = { exchange: 'coinex', value: coinexDays }
          this.setDays(coinexDaysDaysData)

          // setDays kucoin
          const kucoinDays = lineDaysPerformance()
          lastThirtyDaysKucoin.forEach(row => {
            kucoinDays.chartData.labels.push(`${moment(row.day).format()}`)
            kucoinDays.chartData.datasets[0].data.push(row.value)
          })
          const kucoinDaysDaysData = { exchange: 'kucoin', value: kucoinDays }
          this.setDays(kucoinDaysDaysData)

          // setDays okex
          const okexDays = lineDaysPerformance()
          lastThirtyDaysOkex.forEach(row => {
            okexDays.chartData.labels.push(`${moment(row.day).format()}`)
            okexDays.chartData.datasets[0].data.push(row.value)
          })
          const okexDaysDaysData = { exchange: 'okex', value: okexDays }
          this.setDays(okexDaysDaysData)

          // lastSixMonthsEveryFortnight binance
          const binanceMonthlys = barMonthlyPerformance()
          const binanceMonthlysBrl = barMonthlyPerformance()
          lastSixMonthsEveryFortnight.reverse()
          lastSixMonthsEveryFortnight.forEach(row => {
            const sumProfit = ((row.Q1 || 0) + (row.Q2 || 0)).toFixed(2)
            binanceMonthlys.chartData.labels.push(`[$${sumProfit}] - ${moment(row.M).format("MMMM YYYY")}`)
            binanceMonthlys.chartData.datasets[0].data.push(row.Q1)
            binanceMonthlys.chartData.datasets[1].data.push(row.Q2)
          })
          lastSixMonthsEveryFortnightBrl.reverse()
          lastSixMonthsEveryFortnightBrl.forEach(row => {
            const sumProfit = ((row.Q1 || 0) + (row.Q2 || 0)).toFixed(2)
            binanceMonthlysBrl.chartData.labels.push(`${moment(row.M).format("MM YYYY")}`)
            binanceMonthlysBrl.chartData.datasets[0].data.push(row.Q1)
            binanceMonthlysBrl.chartData.datasets[1].data.push(row.Q2)
          })
          const binanceMonthlyData = { exchange: 'binance', value: binanceMonthlys }
          const binanceMonthlyBrlData = { exchange: 'binance', value: binanceMonthlysBrl }
          this.setMonths(binanceMonthlyData)
          this.setMonthsBrl(binanceMonthlyBrlData)


          const huobiMonthlys = barMonthlyPerformance()
          lastSixMonthsEveryFortnightHuobi.reverse()
          lastSixMonthsEveryFortnightHuobi.forEach(row => {
            const sumProfit = ((row.Q1 || 0) + (row.Q2 || 0)).toFixed(2)
            huobiMonthlys.chartData.labels.push(`[$${sumProfit}] - ${moment(row.M).format("MMMM YYYY")}`)
            huobiMonthlys.chartData.datasets[0].data.push(row.Q1)
            huobiMonthlys.chartData.datasets[1].data.push(row.Q2)
          })
          const huobiMonthlyData = { exchange: 'huobi', value: huobiMonthlys }
          this.setMonths(huobiMonthlyData)


          const kucoinMonthlys = barMonthlyPerformance()
          lastSixMonthsEveryFortnightKucoin.reverse()
          lastSixMonthsEveryFortnightKucoin.forEach(row => {
            const sumProfit = ((row.Q1 || 0) + (row.Q2 || 0)).toFixed(2)
            kucoinMonthlys.chartData.labels.push(`[$${sumProfit}] - ${moment(row.M).format("MMMM YYYY")}`)
            kucoinMonthlys.chartData.datasets[0].data.push(row.Q1)
            kucoinMonthlys.chartData.datasets[1].data.push(row.Q2)
          })
          const kucoinMonthlyData = { exchange: 'kucoin', value: kucoinMonthlys }
          this.setMonths(kucoinMonthlyData)


          const okexMonthlys = barMonthlyPerformance()
          lastSixMonthsEveryFortnightOkex.reverse()
          lastSixMonthsEveryFortnightOkex.forEach(row => {
            const sumProfit = ((row.Q1 || 0) + (row.Q2 || 0)).toFixed(2)
            okexMonthlys.chartData.labels.push(`[$${sumProfit}] - ${moment(row.M).format("MMMM YYYY")}`)
            okexMonthlys.chartData.datasets[0].data.push(row.Q1)
            okexMonthlys.chartData.datasets[1].data.push(row.Q2)
          })
          const okexMonthlyData = { exchange: 'okex', value: okexMonthlys }
          this.setMonths(okexMonthlyData)


          const coinexMonthlys = barMonthlyPerformance()
          lastSixMonthsEveryFortnightCoinex.reverse()
          lastSixMonthsEveryFortnightCoinex.forEach(row => {
            const sumProfit = ((row.Q1 || 0) + (row.Q2 || 0)).toFixed(2)
            coinexMonthlys.chartData.labels.push(`[$${sumProfit}] - ${moment(row.M).format("MMMM YYYY")}`)
            coinexMonthlys.chartData.datasets[0].data.push(row.Q1)
            coinexMonthlys.chartData.datasets[1].data.push(row.Q2)
          })
          const coinexMonthlyData = { exchange: 'coinex', value: coinexMonthlys }
          this.setMonths(coinexMonthlyData)


          const ftxMonthlys = barMonthlyPerformance()
          lastSixMonthsEveryFortnightFtx.reverse()
          lastSixMonthsEveryFortnightFtx.forEach(row => {
            const sumProfit = ((row.Q1 || 0) + (row.Q2 || 0)).toFixed(2)
            ftxMonthlys.chartData.labels.push(`[$${sumProfit}] - ${moment(row.M).format("MMMM YYYY")}`)
            ftxMonthlys.chartData.datasets[0].data.push(row.Q1)
            ftxMonthlys.chartData.datasets[1].data.push(row.Q2)
          })
          const ftxMonthlyData = { exchange: 'ftx', value: ftxMonthlys }
          this.setMonths(ftxMonthlyData)

        })
        .then(() => {
          this.requestOn = false
        })
    },

    async hasApi() {
      const { data } = await userInfo()
      this.showConfigApi = (!data.apiIsActive && !data.apiIsActiveFtx && !data.apiIsActiveHuobi && !data.apiIsActiveKucoin && !data.apiIsActiveOkex) ? true : false
    }
  }
};
</script>
<style scoped>
.block-card {
  min-height: 130px!important;
}
</style>
