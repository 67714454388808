import { render, staticRenderFns } from "./NotApi.vue?vue&type=template&id=c369ae5c&scoped=true&"
import script from "./NotApi.vue?vue&type=script&lang=js&"
export * from "./NotApi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c369ae5c",
  null
  
)

export default component.exports