<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-12">

      <card>

        <template slot="header">
          <h4 class="card-title">
            {{ $t('openOperations.title') }} ({{ operations.length }})
            <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
          </h4>

          <base-button class="animation-on-hover" size="sm" type="primary" v-if="operations.length" @click="downloadRun()"> Download </base-button>

        </template>
        <div v-if="blocked === true">
          <base-alert type="danger">
            {{ $t('orders.deactivatedAccount') }}
          </base-alert>
        </div>

        <el-table class="table-striped" v-if="operations.length && blocked === false"
          height="61vh"
          :default-sort="{ prop: 'situation', order: 'descending' }"
          :data="operations.filter(data => !search || data.symbol.toLowerCase().includes(search.toLowerCase()))">
          <el-table-column
            color="primary"
            style="opacity: 0;"
            width="160"
            prop="symbol"
            :label="$t('openOperations.par')">

            <template slot="header">
              <base-input v-model="search"
                type="text"
                :placeholder="$t('openOperations.par')"/>
            </template>

          </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="130"
              prop="exchange"
              :label="$t('closeOperations.exchange')">
            </el-table-column>

          <el-table-column
            prop="price"
            min-width="160"
            :label="$t('openOperations.price')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="buy"
            min-width="160"
            :label="$t('openOperations.boughtBy')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="amount"
            min-width="150"
            :label="$t('openOperations.amount')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="invested"
            min-width="120"
            :label="$t('openOperations.invested')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="currentValue"
            min-width="160"
            :label="$t('openOperations.currentValue')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="situation"
            min-width="150"
            :label="$t('openOperations.situation')"
            sortable>
            <template slot-scope="scope">
             <span :class="situationClass(scope.row.situation)"> {{ viewSituation(scope.row) }} </span>
            </template>
          </el-table-column>

          <el-table-column
            prop="createdAt"
            min-width="130"
            :label="$t('openOperations.date')"
            sortable>
            <template slot-scope="scope">
              {{ viewDate(scope.row.createdAt) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="actions"
            min-width="150"
            :label="$t('openOperations.action')">
            <template slot-scope="scope">
              <base-button class="btn btn-success btn-sm" style="word-break: break-word" size="sm" v-if="showSellNow(scope.row)" @click="sellNow(scope.row)" block>
                {{ $t("openOperations.sellNow") }}
              </base-button>

              <base-button class="btn btn-warning btn-sm" style="word-break: break-word" size="sm" v-if="showSellAtLoss(scope.row)" @click="sellNowLoss(scope.row)" block>
                {{ $t("openOperations.sellAtLoss") }}
              </base-button>

              <base-button class="btn btn-warning btn-sm" style="word-break: break-word" size="sm" v-if="scope.row.price === 0" @click="deleteOperation(scope.row)" block>
                {{ $t("delete") }}
              </base-button>

              <router-link :to="{ name: 'Errors' }" v-if="showErrros(scope.row)">
                <base-button class="btn btn-danger btn-sm" style="word-break: break-word" size="sm" block>
                  {{ $t("orders.error") }}
                </base-button>
              </router-link>
            </template>
          </el-table-column>

        </el-table>
        <div class="text-center" v-if="operations.length === 0 && blocked === false"> {{ $t('openOperations.none') }} </div>
      </card>
    </div>
  </div>
</template>
<script>

import { BaseNav, Badge, BaseAlert } from '@/components'
import { Table, TableColumn } from 'element-ui'
import { mapGetters } from 'vuex'
import moment from 'moment';
import { mountDownloadXlsx } from '../../../../util/download'

export default {
  props: ["ws"],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseNav,
    Badge,
    BaseAlert
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      operations: 'user/openOperations',
      exchange: 'user/exchange',
      blocked: "user/blocked",
    })
  },
  methods: {
    viewDate(date) {
      return moment(date).format()
    },

    viewSituation(row) {
      return `${((row.price * row.amount) - (row.buy * row.amount_buy)).toFixed(2)} (${row.situation}%)`
    },

    showSellNow(row) {
      return row.action && row.request !== true && row.countError < 3
    },

    showSellAtLoss(row) {
      if (this.exchange !== 'binance')
        return !row.action && row.currentValue >= 7 && row.request !== true && row.countError < 3

      if (this.exchange === 'binance')
        return !row.action && row.currentValue >= 11 && row.request !== true && row.countError < 3

      return false
    },

    showErrros(row) {
      return row.countError >= 3
    },

    sellNow(row) {
      if (this.ws.connected) {
        if ((confirm(this.$t("openOperations.doYouSell")) && row.request !== true)) {
          row.request = true
          this.ws.emit('sellNow', { orderId: row.orderId, operationId: row.id });
          setTimeout(() => row.request = false, 10000);
        }
      }
    },

    deleteOperation(row) {
      if (this.ws.connected) {
        if (confirm(this.$t("doYouReally"))) {
          row.request = true
          setTimeout(() => row.request = false, 10000);
          this.ws.emit('deleteOperation', { orderId: row.orderId, operationId: row.id });
        }
      }
    },


    sellNowLoss(row) {
      if (this.ws.connected) {
        if(confirm(this.$t("openOperations.doYouSellLoss"))) {
          row.request = true;
          this.ws.emit('sellNowLoss', { orderId: row.orderId, operationId: row.id });
          setTimeout(() => row.request = false, 10000);
        }
      }
    },

    situationClass(risk) {
      if (risk <= 0) return 'text-warning';
      return 'text-success'
    },

    downloadRun() {
      const namesFields = [
        this.$t('openOperations.par'),
        this.$t('closeOperations.exchange'),
        this.$t('openOperations.price'),
        this.$t('openOperations.boughtBy'),
        this.$t('openOperations.amount'),
        this.$t('openOperations.invested'),
        this.$t('openOperations.currentValue'),
        this.$t('openOperations.situation'),
        this.$t('openOperations.date')
      ]

      const values = []
      const format = this.$root.$i18n.locale === 'br' ? 'DD/MM/YYYY HH:mm' : 'MM-DD-YYYY HH:mm'

      this.operations.forEach(row => {
        const insert = [
          row.symbol,
          row.exchange,
          row.price,
          row.buy,
          row.amount,
          row.invested,
          row.currentValue,
          this.viewSituation(row),
          `(${this.viewDate(row.createdAt)})`
        ]
        values.push(insert)
      })


      const el = mountDownloadXlsx(namesFields, values)
      const wb = XLSX.utils.table_to_book(el, { sheet: this.$t('openOperations.title') });
      return XLSX.writeFile(wb, `${this.$t('openOperations.title')}.xlsx`);
    }
  },

  mounted() {
    this.initXlsx()
  },

  beforeDestroy() {

  }
};
</script>

