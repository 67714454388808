<template>
  <div class="row">
    <div class="col-md-7">
      <card>
        <template slot="header">
          <h4 class="card-title"> {{ $t('payment.walletTitle') }}  </h4>
        </template>
        <div class="card-body" v-if="!requestOn">
          <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
          <div v-if="requestOn === false">
            <h4>
              {{ $t('payment.depositNetwork') }}
            </h4>
            <base-alert type="warning">
              {{ $t('payment.depositNetworkDescription') }}:
            </base-alert>

            <strong> {{ $t('payment.address') }}:</strong>
            <qrcode-vue :value="wallet.address" :size="200" level="H"></qrcode-vue>
            <h5 class="mb-4">  <strong  class="copyOne mr-4" @click="copyText(wallet.address)"> {{ $t('copy') }} </strong>  <span> {{ wallet.address }} </span></h5>

            <strong> {{ $t('payment.memo') }}: </strong>
            <qrcode-vue :value="wallet.memo" :size="100" level="H"></qrcode-vue>
            <h5 class="mb-4"> <strong class="copyOne mr-4" @click="copyText(wallet.memo)"> {{ $t('copy') }} </strong> <span> {{ wallet.memo }} </span>  </h5>

            <base-alert type="danger">
              {{ $t('payment.Attention') }}
            </base-alert>
          </div>
        </div>
        <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
      </card>
    </div>

    <div class="col-md-5">
      <card>
        <template slot="header">
          <h4 class="card-title"> {{ $t("payment.walletBalance") }}  </h4>
        </template>

        <div class="card-body" v-if="!requestOn">
          <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
          <div v-if="requestOn === false">
            <h3 :class="addClass(balance.type)">
              {{ $t('payment.value', { value: balance.value, type: balance.type === 'd' ? $t('payment.debt') : $t('payment.credit') }) }}
            </h3>
            <base-alert type="warning">
              {{ $t('payment.note') }}
            </base-alert>
          </div>
        </div>
        <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
      </card>
    </div>


    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title"> {{ $t('extract.extract') }}  </h4>
        </template>

        <div class="card-body" v-if="!requestOnEx">
          <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
          <div v-if="requestOn === false && rows.data.length">
            <el-table class="table-striped" :data="rows.data">

              <el-table-column
                color="primary"
                style="opacity: 0;"
                min-width="100"
                prop="user"
                :label="$t('extract.indicated')">
              </el-table-column>

              <el-table-column
                color="primary"
                style="opacity: 0;"
                min-width="150"
                prop="type"
                :label="$t('extract.operation')">
                <template slot-scope="scope">
                  <span :class="addClass(scope.row.type)"> {{ scope.row.type }} </span>
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                style="opacity: 0;"
                min-width="150"
                prop="value"
                :label="$t('extract.extractValue')">
                <template slot-scope="scope">
                  <span :class="addClass(scope.row.type)"> {{ scope.row.value }} </span>
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                style="opacity: 0;"
                min-width="160"
                prop="date"
                :label="$t('extract.date')">
                <template slot-scope="scope">
                  <span> {{ showDate(scope.row.date) }} </span>
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                style="opacity: 0;"
                min-width="150"
                prop="description"
                :label="$t('extract.description')">
              </el-table-column>

            </el-table>
            <base-pagination :total="rows.total" v-if="rows.last_page > 1" v-model="rows.current_page" @input="changePage"></base-pagination>
          </div>

          <div class="text-center" v-if="rows.data.length === 0 && requestOnEx === false"> {{ $t('extract.none') }} </div>
        </div>
        <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOnEx" v-loading="true"> </div>
      </card>
    </div>

  </div>
</template>
<script>

import moment from 'moment';
import QrcodeVue from 'qrcode.vue'
import { Table, TableColumn } from 'element-ui'
import { BaseAlert, BaseCheckbox, Badge, Modal, Collapse, CollapseItem, BasePagination } from '@/components'
import { listFinancialCompany, walletApi } from '@/services/index'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    BaseCheckbox,
    Badge,
    Modal,
    Collapse,
    CollapseItem,
    QrcodeVue,
    BasePagination
  },
  data() {
    return {
      requestOn: false,
      requestOnEx: false,
      wallet: {},
      balance: {},
      rows: {
        total: 0,
        current_page: 1,
        last_page: 1,
        data: []
      },
    };
  },
  computed: {

  },
  methods: {

    showDate(date) {
      return moment(date, "DD/MM/YYYY").format()
    },
    changePage(page) {
      this.listEx(page)
    },

    async list() {
      this.requestOn = true
      try {

        let walleTData = await walletApi()
        if (walleTData.data.wallet.wallet_payment === null) {
          walleTData = await walletApi()
        }

        const responseFinancial = await listFinancialCompany()

        const { data, meta } = responseFinancial.data

        this.rows.total = meta.total
        this.rows.current_page = meta.current_page
        this.rows.last_page = meta.last_page
        this.rows.data = data

        this.wallet = { address: walleTData.data.wallet.wallet_payment , memo: `buss${walleTData.data.wallet.user_id}` }
        this.balance = { value: parseFloat(responseFinancial.data.resume.balance.replaceAll("-", "").replaceAll(".", "").replaceAll(",", ".")), type: responseFinancial.data.resume.type === "débito" ? 'd' : 'c'  }

      } catch (e) {
        console.info(e.message)
      }
      this.requestOn = false
    },

    async listEx(page = 1) {
      this.requestOnEx = true
      try {

        const responseFinancial = await listFinancialCompany({ page })

        const { data, meta } = responseFinancial.data

        this.rows.total = meta.total
        this.rows.current_page = meta.current_page
        this.rows.last_page = meta.last_page
        this.rows.data = data

      } catch (e) {
        console.info(e.message)
      }
      this.requestOnEx = false
    },


    copyText(value) {
      const text = value.replace(/\s/g, '')
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.$notify({ type: 'success', message: this.$t("copiedText") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000 });
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    },

    addClass(type) {
      return type !== 'd' ? 'text-success' : 'text-danger'
    },

  },
  async created() {
    await this.list()
    await this.listEx()
  },
  mounted() {

  }
};
</script>

<style scoped>
.copyOne {
  cursor: pointer;
  font-weight: 900;
}
</style>

