<template>
  <div class="container">
    <h1 style="display:none"> {{ $t("meetNeiO") }} </h1>

    <sidebar-share> </sidebar-share>
    <div class="row">
      <div class="col-md-10 mr-auto ml-auto">
        <card>
          <div class="card-body">
            <h1 class="card-title card-custom-text">{{ $t("terms.00") }}</h1>

            <h4 class="card-custom-text"> {{ $t("terms.01") }}</h4>
            <p class="card-text card-custom-text"> {{ $t("terms.02") }} </p>

            <h4 class="mt-4  card-custom-text"> 1. {{ $t("terms.10") }} </h4>
            <p class="card-text card-custom-text"> 1.1 {{ $t("terms.11") }} </p>
            <p class="card-text card-custom-text"> 1.2 {{ $t("terms.12") }} </p>
            <p class="card-text card-custom-text"> 1.3 {{ $t("terms.13") }} </p>

            <h4 class="mt-4 card-custom-text"> 2. {{ $t("terms.20") }} </h4>
            <p class="card-text card-custom-text">2.1 {{ $t("terms.21") }} </p>
            <p class="card-text card-custom-text">2.2 {{ $t("terms.22") }} </p>
            <p class="card-text card-custom-text">2.3 {{ $t("terms.23") }} </p>

            <h4 class="mt-4 card-custom-text"> 3. {{ $t("terms.30") }} </h4>
            <p class="card-text card-custom-text">3.1 {{ $t("terms.31") }} </p>
            <p class="card-text card-custom-text"> 3.2 {{ $t("terms.32") }}</p>
            <p class="card-text card-custom-text mt-4"> {{ $t("terms.32p1") }} </p>
            <p class="card-text card-custom-text mt-4"> {{ $t("terms.32p3") }} </p>
            <p class="card-text card-custom-text mt-4"> {{ $t("terms.32p4") }} </p>

            <h4 class="mt-4 card-custom-text"> 4. {{ $t("terms.40") }} </h4>
            <p class="card-text card-custom-text"> 4.1. {{ $t("terms.41") }} </p>

            <h4 class="mt-4 card-custom-text"> 5. {{ $t("terms.50") }} </h4>
            <p class="card-text card-custom-text">
              5.1. {{ $t("terms.51") }}
            </p>
            <p class="card-text card-custom-text">
              5.2. {{ $t("terms.52") }}
            </p>

            <h4 class="mt-4 card-custom-text"> 6.{{ $t("terms.60") }} </h4>
            <p class="card-text card-custom-text"> 6.1. {{ $t("terms.61") }} </p>
            <p class="card-text card-custom-text"> 6.2. {{ $t("terms.62") }} </p>

            <h4 class="mt-4 card-custom-text"> 7. {{ $t("terms.70") }} </h4>
            <p class="card-text card-custom-text"> 7.1. {{ $t("terms.71") }} </p>
            <p class="card-text card-custom-text"> a) {{ $t("terms.71p1") }} </p>
            <p class="card-text card-custom-text"> b) {{ $t("terms.71p2") }} </p>
            <p class="card-text card-custom-text"> c) {{ $t("terms.71p3") }} </p>
            <p class="card-text card-custom-text"> d) {{ $t("terms.71p4") }} </p>
            <p class="card-text card-custom-text"> e) {{ $t("terms.71p5") }} </p>
            <p class="card-text card-custom-text"> 7.2. {{ $t("terms.72") }} </p>
            <p class="card-text card-custom-text"> 7.3. {{ $t("terms.72") }} </p>

            <h4 class="mt-4 card-custom-text"> 8. {{ $t("terms.80") }} </h4>
            <p class="card-text card-custom-text"> 8.1. {{ $t("terms.81") }} </p>
            <p class="card-text card-custom-text"> 8.2. {{ $t("terms.82") }} </p>
            <p class="card-text card-custom-text"> 8.3. {{ $t("terms.83") }} </p>

            <h4 class="mt-4 card-custom-text"> 9. {{ $t("terms.90") }} </h4>
            <p class="card-text card-custom-text"> 9.1. {{ $t("terms.91") }} </p>
            <p class="card-text card-custom-text"> {{ $t("terms.91p1") }} </p>
            <p class="card-text card-custom-text"> {{ $t("terms.91p2") }} </p>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>

import SidebarShare from '@/pages/Layout/SidebarSharePlugin';

export default {
  components: {
    SidebarShare
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
<style scoped>

</style>
