<template>
  <div class="row">
    <balance :ws="ws" />
    <div class="col-md-12">
      <card :title='$t("frequentlyAskedQuestions.title")'>
        <div class="card-body">
          <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
          <tabs type="primary" vertical class="row">
            <tab-pane :label='tutorial.title' :key="tutorial.key" v-for="tutorial of tutorials">
              <div class="card-body">
                <div type="nav-tabs" :key="`nav-tabs-${row.id}`" v-for="row of tutorial.rows">
                  <h4 class="card-title">
                    {{ row.title }} <br />
                    <base-button size="sm" @click="row.show = !row.show" :type="`${row.show ? 'warning' : 'success'}`">
                      {{ row.show ? $t("frequentlyAskedQuestions.hide") : $t("frequentlyAskedQuestions.show") }}
                    </base-button>
                  </h4>
                  <blockquote class="blockquote mb-0 link-s" v-show="row.show">
                    <div v-html="row.body"></div>
                  </blockquote>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </div>
      </card>
    </div>

  </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from '@/components';
import { tutorialsApi } from "@/services"
import { groupByTutorial } from "@/adapters"

export default {
  props: ['ws'],
  components: {
    TabPane,
    Tabs,
    Collapse,
    CollapseItem
  },
  data() {
    return {
      requestOn: false,
      tutorials: []
    };
  },
  computed: {

  },
  methods: {
    async list() {
      this.requestOn = true
      try {
        const response = await tutorialsApi()
        this.tutorials = groupByTutorial((response.data.tutoriais || []).sort((a,b) => a.order - b.order).map(a => ({ ...a, show: false })))
      } catch (e) {
        console.indo(e)
      }
      this.requestOn = false
    }
  },
  created() {
    this.list()
  },
  mounted() {

  },
  beforeDestroy() {

  }
};
</script>
