<template>
  <div>
    <!-- not API -->
    <div v-if="showConfigApi">
      <not-api />
    </div>

    <!-- with API -->
    <div class="row" v-else>
      <balance :ws="ws" />
      <brokerage-situation :ws="ws" />

      <div class="col-md-12">
        <div class="row">

          <div class="col-md-12">
            <card :title="$t('orders.options')">
              <div class="card-body">
                <div v-if="blocked === true">
                  <base-alert type="danger">
                    {{ $t('orders.deactivatedAccount') }}
                  </base-alert>
                </div>
                <div v-else>
                  <router-link :to="{ name: 'Orders' }">
                    <base-button class="ml-2" size="sm" simple type="success">
                      {{ $t("orders.activeOrders") }}
                    </base-button>
                  </router-link>
                </div>
              </div>
            </card>
          </div>

          <div class="col-md-12">
            <card>

              <template slot="header">
                <h4 class="card-title">
                  {{ $t('orders.ordersRemoved') }} ({{ ordersNotActive.length }})
                  <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
                </h4>
              </template>

              <div class="card-body">
                <div v-if="blocked === true">
                  <base-alert type="danger">
                    {{ $t('orders.deactivatedAccount') }}
                  </base-alert>
                </div>
                <div v-else>
                  <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
                  <div v-if="ordersNotActive.length && requestOn === false">
                    <el-table
                      class="table-striped"
                      height="61vh"
                      :default-sort="{ prop: 'status.value', order: 'descending' }" :data="ordersNotActive.filter(data => !search || data.symbol.toLowerCase().includes(search.toLowerCase()))">

                      <el-table-column type="expand">
                        <template slot-scope="scope">
                          <div class="row">
                            <div class="col-6">
                              <card class="text-uppercase">
                                <div class="card-body max-operation">
                                  <table class="table table-striped">
                                    <thead>
                                      <tr>
                                        <th colspan="2">
                                          <h5 class="title-text">  {{ $t('resume') }} </h5>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.wallet') }}: </strong></td>
                                        <td> {{ scope.row.wallet }} {{ scope.row.group }} </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.inOrder') }}: </strong> </td>
                                        <td> {{ scope.row.inWallet }} {{ scope.row.group }}  </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.inDifference') }}: </strong> </td>
                                        <td> {{ scope.row.inDifference }} {{ scope.row.group }}  </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.inSavedAltcoins') }}: </strong> </td>
                                        <td> {{ gainByOrderIdInfo(scope.row.id).sellOnHold }} {{ scope.row.group }} = {{ ((gainByOrderIdInfo(scope.row.id).symbolOnHold || 0) * scope.row.price).toFixed(2) }} {{ scope.row.currency }} </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <base-button class="btn btn-warning btn-block btn-sm mr-4 mt-2 mb-2" style="word-break: break-word" size="sm" v-if="showAction(scope.row, true)"  @click="cleanNowHold(scope.row)">
                                            {{ $t("dashboard.informSale") }}
                                          </base-button>
                                        </td>
                                        <td>
                                          <base-button class="btn btn-success btn-block btn-sm mt-2 mb-2" style="word-break: break-word" size="sm" v-if="showAction(scope.row)" @click="sellNowHold(scope.row)">
                                            {{ $t("dashboard.sell") }}
                                          </base-button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.averagePrice') }}: </strong> </td>
                                        <td> {{ isNaN(scope.row.averagePrice) ? $t("unavailable") : scope.row.averagePrice }} {{ isNaN(scope.row.averagePrice) ? '' : scope.row.currency }} </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t("closeOperations.profit") }}: </strong> </td>
                                        <td> {{ gainByOrderId(scope.row.id) }} {{ scope.row.currency }} </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.deficit') }}: </strong> </td>
                                        <td> {{ scope.row.deficit }}  {{ scope.row.currency }}  </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.inSoldAltcoins') }}: </strong></td>
                                        <td> {{ ((gainByOrderIdInfo(scope.row.id).holdAccomplished || 0)).toFixed(2) }} {{ scope.row.currency }}  </td>
                                      </tr>
                                      <tr>
                                        <td class="text-right"> <strong> {{ $t('orders.result') }}: </strong> </td>
                                        <td> {{ (((((gainByOrderIdInfo(scope.row.id).symbolOnHold || 0) * scope.row.price)) + gainByOrderId(scope.row.id) + (gainByOrderIdInfo(scope.row.id).holdAccomplished || 0)) - -scope.row.deficit).toFixed(2)  }} {{ scope.row.currency }}  </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </card>
                            </div>
                            <div class="col-6">
                              <card class="text-uppercase">
                                <div class="card-body max-operation">
                                  <div class="row">
                                    <base-alert type="danger" v-if="scope.row.countError >= 3"> {{ scope.row.messageError }} </base-alert>
                                  </div>
                                  <table class="table table-striped">
                                    <thead>
                                      <tr>
                                        <th colspan="4">
                                          <h5 class="title-text">
                                            {{ $t('openOperations.title') }}
                                          </h5>
                                          <base-button class="ml-2 mb-2 mt-1" size="sm" simple  type="warning"  v-if="scope.row.countError >= 3 && onlyView === false" :disabled="scope.row.request"  @click="clearErrors(scope.row)">
                                            {{ $t('orders.clearErrors') }}
                                          </base-button>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th> {{ $t('orders.amount') }} </th>
                                        <th> {{ $t('orders.price') }} </th>
                                        <th> {{ $t("openOperations.situation") }} </th>
                                        <th>
                                          <div v-if="operationsByOrderId(scope.row.id).length > 0">
                                            <base-button class="btn btn-warning btn-sm ml-4 mt-2 mb-2" style="word-break: break-word" size="sm" v-if="showSellAll(scope.row)" @click="actionSellAll(scope.row)" :disabled="scope.row.request">
                                              {{ $t('closeAllOperations') }} - ${{ showValueEstimate(scope.row) }}
                                            </base-button>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr :key="operation.id" v-for="operation of operationsByOrderId(scope.row.id)">
                                        <td> {{ operation.amount }} {{ scope.row.group }} </td>
                                        <td> {{ operation.buy }} {{ scope.row.currency }} </td>
                                        <td> <span :class="situationClass(operation.situation)"> {{ operation.viewSituation }} </span> </td>
                                        <td>
                                          <base-button class="ml-4" size="sm" simple :type="`${(scope.row.inDifference < 0 || operation.amount === 0) ? 'danger' : 'warning'}`" v-if="onlyView === false" @click="deleteOperation(scope.row, operation)" :disabled="scope.row.request">
                                            {{ $t('delete') }}
                                          </base-button>

                                          <base-button
                                            simple
                                            type="success"
                                            size="sm"
                                            class="ml-2"
                                            v-if="showSellNow(operation) && scope.row.inDifference >= 0 && operation.amount > 0 && onlyView === false"
                                            @click="sellNow(operation, scope.row)"
                                            :disabled="scope.row.request">
                                            {{ $t("openOperations.sellNow") }}
                                          </base-button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </card>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                        <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="185"
                        prop="symbol"
                        :label="$t('orders.par')">
                        <template slot="header">
                          <base-input v-model="search"
                            type="text"
                            :placeholder="$t('openOperations.par')"/>
                        </template>


                        <template slot-scope="scope">
                          {{ (scope.row.exchange || exchange).toUpperCase()}}-{{ scope.row.symbol }} <br /> <strong class="tim-icons icon-pencil" style="cursor:pointer" v-if="onlyView === false" @click="editOne(scope.row)"> {{ $t("orders.massEditing") }} </strong>
                        </template>
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        min-width="160"
                        prop="price"
                        sortable
                        :label="$t('orders.price')">
                        <template slot-scope="scope">
                          {{ scope.row.price }} <br /> <strong class="tim-icons icon-chart-bar-32" style="cursor:pointer" @click="showOrderView(scope.row)"> TradingView </strong>
                        </template>
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="170"
                        prop="perfilRender.value"
                        sortable
                        :label="$t('orders.profile')">
                        <template slot-scope="scope">
                          {{ $t(`orders.${scope.row.perfilRender.label}`) }}  <br /> {{ $t('orders.profit') }}: {{ scope.row.profit }}%
                        </template>
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="150"
                        prop="value"
                        sortable
                        :label="$t('orders.value')">
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="120"
                        prop="opens"
                        sortable
                        :label="$t('orders.operations')">
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="100"
                        prop="max"
                        sortable
                        :label="$t('orders.max')">
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="120"
                        prop="entry"
                        sortable
                        :label="$t('orders.entry')">
                        <template slot-scope="scope">
                          {{ scope.row.entry === 0 ? "" : `${scope.row.entry}%` }}
                        </template>
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        min-width="120"
                        prop="profitValue"
                        sortable
                        :label="$t('closeOperations.profit')">
                        <template slot-scope="scope">
                          ${{ valueInIf(scope.row.profitValue) }} <br />
                          &nbsp;
                        </template>
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        min-width="120"
                        prop="hold.value"
                        sortable
                        :label="$t('orders.hold')">
                        <template slot-scope="scope">

                          ${{ valueInIf(scope.row.hold.value) }} <br />
                          <span :class="scope.row.hold.class"> {{ scope.row.hold.label }} </span>
                        </template>
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="130"
                        prop="status.value"
                        sortable
                        :label="$t('orders.status')">
                        <template slot-scope="scope">
                          <span :class="scope.row.status.class" v-html="$t(`orders.${scope.row.status.label}`)"> </span>
                        </template>
                      </el-table-column>

                      <el-table-column
                        color="primary"
                        style="opacity: 0;"
                        min-width="210"
                        prop="action"
                        sortable
                        :label="$t('orders.actions')">
                        <template slot-scope="scope">

                          <base-button class="ml-2" size="sm" simple type="danger"
                            v-if="scope.row.buy_lock && onlyView === false"
                            @click="orderToggle(scope.row)"
                            :disabled="scope.row.request">
                              {{ $t('orders.addFromList') }}
                          </base-button>

                        </template>
                      </el-table-column>


                    </el-table>
                  </div>
                  <div class="text-center" v-if="ordersNotActive.length === 0 && requestOn === false"> {{ $t('orders.none') }} </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>

      <modal size="xl" v-if="modalOpen" :show.sync="modalOpen">
          <div class="embed-responsive" style="min-height:520px">
            <iframe class="embed-responsive-item" :src="uriView" allowfullscreen></iframe>
          </div>

          <div class="m-1" style="font-size: 0.8em">
            <strong> {{ $t('chartByTradingView') }} </strong> <a :href="uriShort" target="_blank"> TradingView </a>
          </div>
          <base-button class="ml-2 float-right" size="sm" simple type="primary" @click="modalOpen = false"> {{ $t("close") }} </base-button>
          <template slot="footer">
          </template>
      </modal>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui'
import { mapGetters, mapActions } from 'vuex'
import { orderToggle, listSymbols, userInfo, symbolsOnHold, listOrders, sellAccomplished, sellInfo  } from "@/services"
import { BaseAlert, Badge, Modal } from 'src/components'
import NotApi from "@/components/NotApi"

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    Badge,
    Modal,
    NotApi
  },
  data() {
    return {
      modalOpen: false,
      showConfigApi: false,
      uriView: '',
      uriShort: '',
      requestOn: false,
      search: ""

    };
  },
  computed: {
    ...mapGetters({
      show: 'user/showBalance',
      orders: 'user/orders',
      exchange: 'user/exchange',
      blocked: "user/blocked",
      ordersSelected: "user/ordersSelected",
      operationsByOrderId: "user/operationsByOrderId",
      gainByOrderId: "user/gainByOrderId",
      gainByOrderIdInfo: "user/gainByOrderIdInfo",
      holdBySymbol: "user/holdBySymbol",
      onlyView: "user/onlyView"
    }),
    ordersActive: function() {
      return this.orders.filter(o => o.show)
    },
    ordersNotActive: function() {
      return this.orders.filter(o => !o.show)
    }
  },
  created() {
    this.listSymbols()
  },
  methods: {
    ...mapActions({
      setSymbolsOnHold: "user/setSymbolsOnHold",
      setSymbolsOnHoldBrl: "user/setSymbolsOnHoldBrl",
      setGain: "user/setGain"
    }),

    showAction (row, info = false) {
      if (row.sellRequest === true)
        return false

      if (row.exchange.toUpperCase() === "BINANCE" && info === false && ((this.gainByOrderIdInfo(row.id).symbolOnHold || 0) * row.price) < 15)
        return false

      if (row.exchange.toUpperCase() === "KUCOIN" && info === false && ((this.gainByOrderIdInfo(row.id).symbolOnHold || 0) * row.price) < 5)
        return false

      if (row.exchange.toUpperCase() === "HUOBI" && info === false && ((this.gainByOrderIdInfo(row.id).symbolOnHold || 0) * row.price) < 7)
        return false

      if (((this.gainByOrderIdInfo(row.id).symbolOnHold || 0) * row.price) === 0)
        return false

      return true
    },

    // show and action sellAll
    actionSellAll(order) {
      if (this.ws.connected) {
        if ((confirm(this.$t("dashboard.sellAll")) && order.request !== true)) {
          order.request = true
          this.ws.emit('orderSellAllAndBlock', { orderId: order.id });
          setTimeout(() => {
            order.request = false
          }, 7000);
        }
      }
    },

    showValueEstimate(order) {
      let value = 0
      if (order.operations.length > 0)
        value = parseFloat((order.operations.map(op => op.amount).reduce((a, b) => a + b, 0) * order.price).toFixed(2))

      return value
    },

    showSellAll(order) {
      const value = this.showValueEstimate(order)

      if (order.exchange.toUpperCase() === "BINANCE" && value < 15)
        return false

      if (order.exchange.toUpperCase() === "OKEX" && value < 7)
        return false

      if (order.exchange.toUpperCase() === "KUCOIN" && value < 5)
        return false

      if (order.exchange.toUpperCase() === "HUOBI" && value < 7)
        return false

      return true
    },

    async cleanNowHold (row) {
      if(confirm(this.$t('dashboard.cleanHold', { coin: row.group }))) {
        try {
          const body = {
            "orderId": row.id
          }

          row.sellRequest = true
          await sellInfo(body)

          const resOrders = await listOrders()
          this.setGain(resOrders.data)

          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 7000 });
        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422 || status === 400) {
            let errors = []
            for (const item in data) {
              errors.push(data[item].join("\n"))
            }
            message = errors.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 7000 });
        }
        setTimeout(() => row.sellRequest = false, 7000);
      }
    },

    async sellNowHold (row) {
      if(confirm(this.$t('dashboard.sellHold', { coin: row.group }))) {
        try {
          const body = {
            "orderId": row.id,
            "sellOnHold": this.gainByOrderIdInfo(row.id).sellOnHold
          }

          row.sellRequest = true
          await sellAccomplished(body)

          const resOrders = await listOrders()
          this.setGain(resOrders.data)

          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 7000 });
        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422 || status === 400) {
            let errors = []
            for (const item in data) {
              errors.push(data[item].join("\n"))
            }
            message = errors.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 7000 });
        }
        setTimeout(() => row.sellRequest = false, 7000);
      }
    },

    sellNow(row, order) {
      if (this.ws.connected) {
        if ((confirm(this.$t("openOperations.doYouSell")) && row.request !== true)) {
          row.request = true
          order.request = true
          this.ws.emit('sellNow', { orderId: row.orderId, operationId: row.id });
          setTimeout(() => {
            row.request = false
            order.request = false
          }, 7000);
        }
      }
    },

    showSellNow(row) {
      return row.action && row.request !== true && row.countError < 3
    },

    showOrderView(row) {
      this.uriView = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_a6e15&symbol=${row.exchange}%3A${row.symbol.replace('/', '').replace('-', '')}&interval=D&details=1&hide_side_toolbar=0&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&theme=dark&style=1&timezone=Etc%2FUTC&withdateranges=1&showpopupbutton=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&showpopupbutton=1&locale=en&utm_source=tradio-vuejs.vercel.app&utm_medium=widget&utm_campaign=chart&utm_term=${row.exchange}%3A${row.symbol.replace('/', '').replace('-', '')}`
      this.uriShort = `https://br.tradingview.com/symbols/${row.symbol.replace('/', '').replace('-', '')}/?exchange=${row.exchange}`
      this.modalOpen = true
    },


    editOne(row) {
      this.orders.forEach(o => o.selected = false)
      row.selected = true
      this.$router.push({ name: 'OrdersEdit' })
    },

    orderLockOrUnlock(row) {
      const orderId = row.id
      const action = !row.buy_lock

      if (this.ws.connected) {
        if (confirm(this.$t("doYouReally"))) {
          row.request = true
          setTimeout(() => row.request = false, 7000);
          this.ws.emit('orderLockOrUnlock', { orderId, action });
        }
      }
    },
    async orderToggle(row) {
      if (confirm(this.$t("doYouReally"))) {
        row.request = true
        try {
          await orderToggle(row.id, { show: !row.show })
          setTimeout(() => row.request = false, 7000);
        } catch (e) {
          console.error(e)
        }

      }
    },

    deleteOperation(row, operation) {
      if (this.ws.connected) {
        if (confirm(this.$t("doYouReally"))) {
          row.request = true
          setTimeout(() => row.request = false, 5000);
          this.ws.emit('deleteOperation', { orderId: row.id, operationId: operation.id });
        }
      }
    },

    clearErrors(row) {
      const orderId = row.id
      const action = !row.buy_lock

      if (this.ws.connected) {
        if (confirm(this.$t("doYouReally"))) {
          row.request = true
          setTimeout(() => row.request = false, 7000);
          this.ws.emit('orderCleanErrors', { orderId });
        }
      }
    },

    situationClass(risk) {
      if (risk <= 0) return 'text-warning';
      return 'text-success'
    },

    async listSymbols() {
      this.requestOn = true
      try {
        const res = await listSymbols()
        this.symbols = res.data.map(s => ({ id: s.id, symbol: s.symbol }))

        const resUser = await userInfo()
        this.max_buy = resUser.data.max_buy
        this.apiIsActive = resUser.data.apiIsActive
        this.apiIsActiveFtx = resUser.data.apiIsActiveFtx
        this.apiIsActiveHuobi = resUser.data.apiIsActiveHuobi
        this.apiIsActiveKucoin = resUser.data.apiIsActiveKucoin

        this.showConfigApi = (!resUser.data.apiIsActive && !resUser.data.apiIsActiveFtx && !resUser.data.apiIsActiveHuobi && !resUser.data.apiIsActiveKucoin && !resUser.data.apiIsActiveOkex) ? true : false

        const response = await symbolsOnHold()

        // setSymbolsOnHold binance
        const binanceSymbolsOnHold = { exchange: 'binance', value: response.symbolsOnHold }
        const binanceSymbolsOnHoldBrl = { exchange: 'binance', value: response.symbolsOnHoldBrl }

        this.setSymbolsOnHold(binanceSymbolsOnHold)
        this.setSymbolsOnHoldBrl(binanceSymbolsOnHoldBrl)

        const resOrders = await listOrders()
        this.setGain(resOrders.data)

      } catch (e) {
        console.error(e)
      }
      this.requestOn = false
    },
  }
};
</script>
<style></style>
