<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <base-button
            simple
            size="sm"
            type="warning"
            class="mb-3"
            v-if="rows.data.filter(i => i.isAdmin === false && i.debit >= 10).length && showAdmin"
            :disabled="requestOn === true"
            @click="blockAllDebit(rows.data.filter(i => i.isAdmin === false && i.debit >= 10))">
              {{ $t("deactivateAccountsOnDebits") }}
          </base-button>
        </template>
      </card>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <card>
            <base-input type="text" label="Search" placeholder="Search" @keyup.enter.native="SearchValue" v-model="sValue" />
            <base-button
                simple
                size="sm"
                type="warning"
                class="mb-3"
                @click="Search('')">
                Find
              </base-button>
          </card>
        </div>

        <div class="col-md-6">
          <card>
            <base-input type="text" label="UUI" placeholder="UUI"  @keyup.enter.native="UuiValue" v-model="uValue" />
            <base-button
                simple
                size="sm"
                type="warning"
                class="mb-3"
                @click="Search('')">
                Find
              </base-button>
          </card>
        </div>

        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title"> {{ $t('users') }} ({{ rows.total }})</h4>

              <div v-if="rows.data.length">
                <fieldset :class="{ 'opacity-25': requestOn === true }">
                  <el-table
                    class="table-striped"
                    @sort-change="sortChange"
                    :data="rows.data">

                    <el-table-column type="expand">
                      <template slot-scope="scope">
                        <div class="row">
                          <div class="col-12">
                            <card class="text-uppercase">
                              <template slot="header">
                                <h4 class="card-title">
                                  {{ $t('actions') }}
                                </h4>
                              </template>
                              <div class="card-body max-operation">
                                <base-button
                                  simple
                                  size="sm"
                                  type="warning"
                                  class="ml-3"
                                  v-if="scope.row.two_active === true"
                                  :disabled="requestOn === true"
                                  @click="remove2FA(scope.row.id)">
                                    {{ $t("remove2FA") }}
                                </base-button>

                                <base-button
                                  simple
                                  size="sm"
                                  type="primary"
                                  class="ml-3"
                                  v-if="scope.row.is_active === false"
                                  :disabled="requestOn === true"
                                  @click="updateActive(scope.row.id)">
                                    {{ $t("activeMail") }}
                                </base-button>

                                <base-button
                                  simple
                                  size="sm"
                                  :type="scope.row.active === false ? 'warning' : 'success'"
                                  class="ml-3"
                                  :disabled="requestOn === true"
                                  @click="activateOrBlock(scope.row)">
                                    {{ scope.row.active === false ? $t("default.block") : $t("default.activate") }}
                                </base-button>

                              </div>
                            </card>
                          </div>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="70"
                      prop="id"
                      :label="$t('default.id')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="130"
                      prop="active"
                      :label="$t('default.blocked')">
                      <template slot-scope="scope">
                        <span :class="{ 'text-success': !scope.row.active, 'text-danger': scope.row.active }"> {{ scope.row.active ? $t('yes') : $t('no') }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="debit"
                      sortable
                      :label="$t('default.debit')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="200"
                      prop="uui"
                      :label="$t('uui')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="200"
                      prop="email"
                      sortable
                      :label="$t('default.email')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="apiIsActive"
                      :label="$t('default.api', { e: 'B' })">
                      <template slot-scope="scope">
                        <span :class="{ 'text-success': scope.row.apiIsActive, 'text-danger': !scope.row.apiIsActive }"> {{ scope.row.apiIsActive ? $t('yes') : $t('no') }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="usdt"
                      sortable
                      :label="$t('default.usd', { e: 'B', u: 'USDT' })">
                      <template slot-scope="scope">
                        <span> {{ scope.row.usdt.toFixed(2) }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="busd"
                      sortable
                      :label="$t('default.usd', { e: 'B', u: 'BUSD' })">
                      <template slot-scope="scope">
                        <span> {{ scope.row.busd.toFixed(2) }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="apiIsActiveKucoin"
                      :label="$t('default.api', { e: 'K' })">
                      <template slot-scope="scope">
                        <span :class="{ 'text-success': scope.row.apiIsActiveKucoin, 'text-danger': !scope.row.apiIsActiveKucoin }"> {{ scope.row.apiIsActiveKucoin ? $t('yes') : $t('no') }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="usdt_k"
                      sortable
                      :label="$t('default.usd', { e: 'K', u: 'USDT' })">
                      <template slot-scope="scope">
                        <span> {{ scope.row.usdt_k.toFixed(2) }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="apiIsActiveHuobi"
                      :label="$t('default.api', { e: 'H' })">
                      <template slot-scope="scope">
                        <span :class="{ 'text-success': scope.row.apiIsActiveHuobi, 'text-danger': !scope.row.apiIsActiveHuobi }"> {{ scope.row.apiIsActiveHuobi ? $t('yes') : $t('no') }} </span>
                      </template>
                    </el-table-column>

                    <!-- <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="husd"
                      sortable
                      :label="$t('default.usd', { e: 'H', u: 'HUSD' })">
                      <template slot-scope="scope">
                        <span> {{ scope.row.husd.toFixed(2) }} </span>
                      </template>
                    </el-table-column> -->

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="usdt_h"
                      sortable
                      :label="$t('default.usd', { e: 'H', u: 'USDT' })">
                      <template slot-scope="scope">
                        <span> {{ scope.row.usdt_h.toFixed(2) }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="apiIsActiveOkex"
                      :label="$t('default.api', { e: 'O' })">
                      <template slot-scope="scope">
                        <span :class="{ 'text-success': scope.row.apiIsActiveOkex, 'text-danger': !scope.row.apiIsActiveOkex }"> {{ scope.row.apiIsActiveOkex ? $t('yes') : $t('no') }} </span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      style="opacity: 0;"
                      min-width="120"
                      prop="usdt_o"
                      :label="$t('default.usd', { e: 'O', u: 'USDT' })">
                      <template slot-scope="scope">
                        <span> {{ scope.row.usdt_o.toFixed(2) }} </span>
                      </template>
                    </el-table-column>



                  </el-table>
                  <base-pagination :total="rows.total" v-model="rows.current_page" @input="changePage"></base-pagination>
                </fieldset>
              </div>
              <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
              <div class="text-center" v-if="rows.data.length === 0 && requestOn === false"> {{ $t('none') }} </div>

            </template>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { Table, TableColumn } from 'element-ui'
import { usersList, usersPut } from "@/services/index"
import { BasePagination } from '@/components'
import { mapGetters } from 'vuex'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination
  },
  data() {
    return {
      requestOn: false,
      profit: false,
      uValue: "",
      sValue: "",
      sorter: { "column": '', "type": '' },
      pendency: 0,
      nextValue: 0,
      pendencyCompany: 0,
      pendencyBusiness: 0,
      profitViews: [],
      profitFees: [],
      rows: {
        total: 0,
        current_page: 1,
        data: []
      }
    };
  },

  methods: {
    async rowsUsers(params = { page: 1}) {
      this.requestOn = true
      try {

        let hasValue = {}

        if (this.sValue.replace(/\s/g, '').length > 0)
          hasValue.search = this.sValue

        if (this.uValue.replace(/\s/g, '').length > 0)
          hasValue.uui = this.uValue


        const response = await usersList({ ...params, sorter: this.sorter, ...hasValue } )
        const { data, pendency, pendencyCompany, pendencyBusiness = 0, meta, nextValue, profitViews = [], profitFees = [] } = response.data

        this.pendency = pendency.toFixed(2)
        this.nextValue = nextValue.toFixed(2)
        this.pendencyCompany = pendencyCompany.toFixed(2)
        this.pendencyBusiness = pendencyBusiness.toFixed(2)


        this.rows.total = meta.total
        this.rows.current_page = meta.current_page
        this.rows.data = data

        const monthsProfit = []

        for (let i = 0; i < profitFees.length; i++) {
          const row = profitFees[i]
          const key = monthsProfit.findIndex( m => m.label === row.label)
          if (key === -1) {
            monthsProfit.push(this.parseRow(row))
          } else {
            const rowParsed = this.parseRow(row)
            monthsProfit[key].revenue += rowParsed.revenue
            monthsProfit[key].revenue_real += rowParsed.revenue_real
            monthsProfit[key].received += rowParsed.received
            monthsProfit[key].unpaid += rowParsed.unpaid
            monthsProfit[key].value_of_referrals += rowParsed.value_of_referrals
            monthsProfit[key].profit += rowParsed.profit
            monthsProfit[key].items.unshift(rowParsed.items[0])
          }
        }
        this.profitFees = monthsProfit

      } catch (e) {
        this.rows.total = 0
        this.rows.current_page = 1
        this.rows.data = []
        console.info(e)
      }
      this.requestOn = false
    },

    parseRow(row) {
      return {
        label: row.label,
        revenue: parseFloat(row.revenue.toFixed(2)),
        revenue_real: parseFloat(row.revenue_real.toFixed(2)),
        received: parseFloat(row.received.toFixed(2)),
        unpaid: parseFloat(row.unpaid.toFixed(2)),
        value_of_referrals: parseFloat(row.value_of_referrals.toFixed(2)),
        profit: parseFloat(row.revenue_real.toFixed(2)) - parseFloat(row.unpaid.toFixed(2)) - parseFloat(row.value_of_referrals.toFixed(2)),
        items: [{
          start_date: row.start_date,
          end_date: row.end_date,
          revenue: parseFloat(row.revenue.toFixed(2)),
          revenue_real: parseFloat(row.revenue_real.toFixed(2)),
          received: parseFloat(row.received.toFixed(2)),
          unpaid: parseFloat(row.unpaid.toFixed(2)),
          value_of_referrals: parseFloat(row.value_of_referrals.toFixed(2)),
          deposit: parseFloat(row.deposit.toFixed(2)),
          profit: parseFloat(row.revenue_real.toFixed(2)) - parseFloat(row.unpaid.toFixed(2)) - parseFloat(row.value_of_referrals.toFixed(2)),
        }]
      }
    },

    async changePage(page) {
      await this.rowsUsers({ page })
    },

    async sortChange(row) {
      console.info(row)
      const { prop, order } = row
      const type = (order || 'a').substring(0, 1) === 'a' ? 'ASC' : "DESC"
      this.sorter = { "column": prop, "type": type }

      await this.rowsUsers({ page: 1 })
    },

    async SearchValue() {
      this.uValue = ""
      await this.rowsUsers({ page: 1 })
    },

    async UuiValue() {
      this.sValue = ""
      await this.rowsUsers({ page: 1 })
    },

    async Search() {
      await this.rowsUsers({ page: 1 })
    },

    remove2FA(userId) {
      if (confirm(this.$t("reallyRemoveJFA", { id: userId}))) {
        this.loading = true

        usersPut(userId, { two_active: false })
          .then( () => this.rowsUsers())
          .catch( error => alert(error.message))
          .then(() => this.loading = false)
      }

    },

    updateActive(userId) {
      if (confirm(this.$t("reallyRemoveJFA", { id: userId}))) {
        this.loading = true

        usersPut(userId, { is_active: true })
          .then( () => this.rowsUsers())
          .catch( error => alert(error.message))
          .then(() => this.loading = false)
      }

    },

    activateOrBlock(user) {
      const message = user.active ? this.$t("default.block") : this.$t("default.block")
      if (confirm(this.$t("default.ifAction", { action: message, id: user.id }))) {
        this.requestOn = true
        this.ws.emit('blockedUser', { userId: parseInt(user.id), action: !user.active });
        setTimeout(() => this.rowsUsers(), 5000);
      }
    },

    blockAllDebit(rows) {
      if (this.ws.connected) {
        const msg = `${ this.$t("usersDisableAll", { len: rows.length }) }`;
        if (confirm(msg)) {
          this.requestOn = true
          rows.forEach(user => {
            this.ws.emit('blockedUser', { userId: user.id, action: true });
          });

          setTimeout(() => this.rowsUsers(), 5000);
        }
      }
    }

  },

  async created() {
    await this.rowsUsers()
  },
  computed: {
    ...mapGetters({
      showAdmin: 'user/showAdmin',
    })
  },
};
</script>
<style lang="scss">
  .opacity-25 {
    opacity: 0.25;
  }
</style>
