import { Table } from "element-ui"

export const mountDownloadXlsx = (namesFields, rows) => {
  let tableString = "<table>"

  // thead
  tableString += "<thead>"
  namesFields.forEach(name => {
    tableString += `<th>${ name }</th>`
  })
  tableString += "</thead>"

  // tbody
  tableString += "<tbody>"
  rows.forEach(field => {
    tableString +=  "<tr>"
      field.forEach(value => {
        tableString +=  `<td> ${value} </td>`
      })
    tableString +=  "/<tr>"
  })
  tableString += "</tbody>"

  tableString += "</table>"
  const tableEl = document.createElement('table')
  tableEl.innerHTML = tableString.trim()
  return tableEl;
}
